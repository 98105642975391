 <template>
  <div class="mt-14 mb-10 row middle-xs">
    <div class="col-xs-12 grow-0 col-sm mr-7 mb-6 sm:mb-0">
        <a v-if="buttonText" href="/pricing"
          class="btn btn--primary btn--mobile-full-width"
          >
          {{ buttonText }}
        </a>
    </div>
    <div class="col-xs-12 col-sm-8 mb-10 sm:mb-0">
      <div class="row center-xs start-sm middle-xs">
        <div class="sm:mr-2 mb-2 sm:mb-0">
          <StarRating :rating="4.2"> </StarRating>
        </div>
        <div class="col-xs-12 col-sm-6">{{ ratingText }}</div>
      </div>
    </div>
  </div>
</template>

<script>

  import StarRating from "../StarRating";

  export default {
    name: "PageHeaderRating",
    components: {
      StarRating
    },
    props: {
      buttonText: {
      type: String,
      default: "Button Text"
      },
      ratingText: {
        type: String,
        default: "Rating Text"
      }
    }
  }
</script>