<template>
  <DefaultLayout :theme="theme">
    <SubNavigation
      :links="[
        {
          text: 'Campaign Builder',
          icon: 'campaign-builder',
          url: '/features/campaignbuilder',
        },
        {
          text: 'Automations',
          icon: 'automations',
          url: '/features/automations',
        },
        {
          text: 'AI Content Assistant',
          icon: 'ai',
          url: '/features/ai-content-assistant',
        },
        {
          text: 'Analytics',
          icon: 'analytics',
          url: '/features/analytics',
        },
        {
          text: 'Signup Forms',
          icon: 'signup-forms',
          url: '/features/signupforms',
        },
        {
          text: 'Transactional Emails',
          icon: 'transactional',
          url: '/features/transactionalemails',
        },
        {
          text: 'Website Tracking',
          icon: 'website-tracking',
          url: '/features/websitetracking',
        },
      ]"
      :active="this.active"
      :theme="theme"
    ></SubNavigation>
    <slot />
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import SubNavigation from "@/components/Features/SubNavigation.vue";

export default {
  name: "FeaturePage",
  components: {
    DefaultLayout,
    SubNavigation,
  },
  props: {
    theme: {
      type: String
    }
  },
  data() {
    return {
      active: window.location.pathname,
    };
  },
};
</script>
