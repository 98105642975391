<template>
  <svg :class="getClass" width="193" height="48" viewBox="0 0 193 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.0156 37.6063L64.406 32.1638C66.9891 34.0535 70.4204 35.0362 73.8132 35.0362C76.3192 35.0362 77.9 34.0913 77.9 32.6551C77.8614 28.6488 62.9024 31.7858 62.7867 21.7323C62.7482 16.6299 67.3747 12.6992 73.9289 12.6992C77.8229 12.6992 81.7168 13.6441 84.4927 15.7984L82.2566 21.3543C79.712 19.7669 76.5506 18.6331 73.5433 18.6331C71.5 18.6331 70.1506 19.578 70.1506 20.7874C70.1891 24.7181 85.2638 22.5638 85.418 32.1638C85.418 37.3795 80.9072 41.0457 74.4301 41.0457C69.6879 41.0457 65.3313 39.9496 62.0156 37.6063ZM153.736 30.1984C152.541 32.2394 150.305 33.6378 147.722 33.6378C143.905 33.6378 140.82 30.6142 140.82 26.8724C140.82 23.1307 143.905 20.1071 147.722 20.1071C150.305 20.1071 152.541 21.5055 153.736 23.5465L160.329 19.9559C157.861 15.6472 153.119 12.6992 147.722 12.6992C139.741 12.6992 133.264 19.0488 133.264 26.8724C133.264 34.6961 139.741 41.0457 147.722 41.0457C153.158 41.0457 157.861 38.1354 160.329 33.789L153.736 30.1984V30.1984ZM88.7337 0.944885H96.9843V40.5165H88.7337V0.944885ZM163.567 0.944885V40.5165H171.818V28.6488L181.611 40.5165H192.175L179.722 26.4189L191.249 13.2661H181.148L171.818 24.189V0.944885H163.567ZM121.505 30.274C120.31 32.2016 117.842 33.6378 115.066 33.6378C111.249 33.6378 108.165 30.6142 108.165 26.8724C108.165 23.1307 111.249 20.1071 115.066 20.1071C117.842 20.1071 120.31 21.6189 121.505 23.5843V30.274ZM121.505 13.2661V16.4787C120.155 14.2488 116.801 12.6992 113.293 12.6992C106.045 12.6992 100.339 18.9732 100.339 26.8346C100.339 34.6961 106.045 41.0457 113.293 41.0457C116.801 41.0457 120.155 39.4961 121.505 37.2661V40.4787H129.755V13.2661H121.505Z" fill="black" fill-opacity="0.25"/>
    <path d="M11.0084 30.2362C11.0084 32.9953 8.73369 35.2252 5.91923 35.2252C3.10478 35.2252 0.830078 32.9953 0.830078 30.2362C0.830078 27.4772 3.10478 25.2473 5.91923 25.2473H11.0084V30.2362ZM13.553 30.2362C13.553 27.4772 15.8277 25.2473 18.6421 25.2473C21.4566 25.2473 23.7313 27.4772 23.7313 30.2362V42.7087C23.7313 45.4677 21.4566 47.6976 18.6421 47.6976C15.8277 47.6976 13.553 45.4677 13.553 42.7087V30.2362Z" fill="black" fill-opacity="0.25"/>
    <path d="M18.6416 10.2047C15.8272 10.2047 13.5525 7.97481 13.5525 5.21575C13.5525 2.4567 15.8272 0.226776 18.6416 0.226776C21.4561 0.226776 23.7308 2.4567 23.7308 5.21575V10.2047H18.6416ZM18.6416 12.737C21.4561 12.737 23.7308 14.9669 23.7308 17.726C23.7308 20.485 21.4561 22.715 18.6416 22.715H5.88017C3.06571 22.715 0.791016 20.485 0.791016 17.726C0.791016 14.9669 3.06571 12.737 5.88017 12.737H18.6416Z" fill="black" fill-opacity="0.25"/>
    <path d="M39.0373 17.726C39.0373 14.9669 41.312 12.737 44.1265 12.737C46.941 12.737 49.2157 14.9669 49.2157 17.726C49.2157 20.485 46.941 22.715 44.1265 22.715H39.0373V17.726ZM36.4928 17.726C36.4928 20.485 34.2181 22.715 31.4036 22.715C28.5892 22.715 26.3145 20.485 26.3145 17.726V5.21575C26.3145 2.4567 28.5892 0.226776 31.4036 0.226776C34.2181 0.226776 36.4928 2.4567 36.4928 5.21575V17.726V17.726Z" fill="black" fill-opacity="0.25"/>
    <path d="M31.4036 37.7197C34.2181 37.7197 36.4928 39.9496 36.4928 42.7087C36.4928 45.4677 34.2181 47.6976 31.4036 47.6976C28.5891 47.6976 26.3145 45.4677 26.3145 42.7087V37.7197H31.4036ZM31.4036 35.2252C28.5891 35.2252 26.3145 32.9953 26.3145 30.2362C26.3145 27.4772 28.5891 25.2473 31.4036 25.2473H44.165C46.9795 25.2473 49.2542 27.4772 49.2542 30.2362C49.2542 32.9953 46.9795 35.2252 44.165 35.2252H31.4036Z" fill="black" fill-opacity="0.25"/>
  </svg>
</template>

<script>
export default {
  name: "SlackLogo",
  props: {
    class: {
      type: String
    }
  },
  computed: {
    getClass() {
      return this.class;
    }
  }
}
</script>