<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>
  <div>
    <navigation></navigation>

    <PageHeader title="Privacy Policy"> </PageHeader>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1">
          <div class="longform">
            <p>
              MPZMail Limited (a private limited company registered in England
              with number 11234241 whose registered office is at 20 Dale St,
              Manchester, M1 1EZ) (<strong>"we"</strong>, <strong>"us"</strong>,
              <strong>"our"</strong>) are the controller of your Personal Data
              and are committed to protecting and respecting your privacy.
            </p>
            <p>
              This privacy policy (<strong>"Privacy Policy"</strong>), together
              with our <a href="/Terms">Terms and Conditions</a> and any other
              documents referred to in this Privacy Policy, sets out the basis
              on which any personally identifiable information (<strong
                >"Personal Data"</strong
              >) we collect from you, or that you provide to us, will be
              processed by us. Please read the following carefully to understand
              our views and practices regarding your Personal Data and how we
              will treat it in respect of your use of Transpond.io (<strong
                >"the Service"</strong
              >).
            </p>
            <p>
              By using the Service, you are accepting the terms of this Privacy
              Policy. In the event of a conflict between the terms of this
              Privacy Policy and the Terms and Conditions, the Terms and
              Conditions will prevail.
            </p>
            <p>
              This Privacy Policy does not apply to any data you provide to us
              when we process personal data on your behalf as your data
              processor i.e. where we process customer data within the cloud
              service we provide to you, as a Business to Business service
              provider.
            </p>

            <h3>Legal basis for processing</h3>
            <p>
              We collect and use the personal data described below in order to
              provide you with access to our Service in a reliable and secure
              manner. We also collect and use personal data:
            </p>
            <ul>
              <li>
                For our legitimate business needs (which includes providing our
                Service to you where we do not have a contract in place with
                you); promoting our business and informing our marketing
                strategy; enforcing our legal and contractual rights; improving
                our Service for the benefit of you and other Users; IT and
                network security and prevention of fraud.
              </li>
              <li>To comply with a legal obligation.</li>
            </ul>
            <p>
              To the extent we process your personal data for any other
              purposes, we ask for your consent in advance or require that our
              partners obtain such consent.
            </p>

            <h3>Information we may hold about you</h3>
            <p>
              We may collect, receive and process the following information
              about you.
            </p>
            <p>
              <strong>Information you give us.</strong> If you decide to
              register with or use the Service you will provide your name,
              company name, email address, contact details and if applicable
              payment information.
            </p>
            <p>
              <strong>Information we collect about you.</strong>
              Each time you use the Service we may automatically collect
              information about your device and your usage of our Service, which
              may qualify as Personal Data, for example:
            </p>
            <ul>
              <li>
                Technical information that your browser sends whenever you visit
                a website or your mobile app sends when you are using it. This
                log data may include your Internet Protocol (IP) address, the
                address of the web page you visited before using the Service,
                your browser type and settings, the date and time of your use of
                the Service, information about your browser configuration and
                plug-ins, language preferences and cookie data.
              </li>
              <li>
                Information about your visit, including the full Uniform
                Resource Locators (URL) clickstream to, through and from our
                website (including date and time); products you viewed or
                searched for; page response times, download errors, length of
                visits to certain pages, page interaction information (such as
                scrolling, clicks, and mouse-overs), and methods used to browse
                away from the page and any phone number used to call our
                customer service number.
              </li>
              <li>
                We may also collect publicly accessible data about you from
                social media websites that you visit when using the Service.
              </li>
              <li>
                We may also collect non-personally identifiable information
                (e.g. gender and location) to assist in providing the Service to
                you. Non-personally identifiable information by itself cannot be
                used to identify or contact you. However, this information may
                be combined with other identifiers in a way that enables you to
                be identified.
              </li>
            </ul>
            <p>
              <strong>Information we obtain from other sources.</strong>
              We also collect information about you if you use any of the other
              websites we operate or the services we provide or from publicly
              available sources. We may combine this information with personal
              data provided by you. This helps us update, expand, and analyse
              our records, identify new customers, and create more tailored
              advertising to provide services that may be of interest to you. We
              also use this for the purposes of targeted advertising, delivering
              relevant email content, event promotion and profiling, determining
              eligibility and verifying contact information. The Personal Data
              we collect includes:
            </p>
            <ul>
              <li>
                Publicly available information about you; such as information
                published about you, for example by Companies House, including
                postal address, job title, email address, phone number,
                professional or employment-related information, education
                information and commercial information.
              </li>
              <li>
                Internet activities: internet data (or user behavioural data),
                IP addresses, internet activity information and inferences about
                your preferences and behaviour.
              </li>
              <li>
                Social media profiles: information published about you on social
                media profiles such as LinkedIn, Facebook, Twitter etc.
              </li>
            </ul>
            <p>
              <strong>Information collected by third parties.</strong> We are
              also working closely with third parties (including, for example,
              business partners, sub-contractors in technical, payment and
              delivery services, advertising networks, analytics providers,
              email notification providers, system security providers, hosting
              providers, search information providers, credit reference
              agencies) and may receive information about you from them.
            </p>
            <p>
              <a href="/thirdparties"
                >View the third parties Transpond uses here</a
              >
            </p>

            <h3>Use of cookies</h3>
            <p>
              We use cookies on our website to distinguish you from other users
              of our website and Service. This helps us to provide you with a
              good experience when you browse our website and also allows us to
              improve the website and Service. For detailed information on the
              cookies we use and the purposes for which we use them see our
              <a href="/cookiepolicy">Cookie Policy</a>.
            </p>

            <h3>If you are under 16 years of age</h3>
            <p>
              If you're under the age of 16, you may not have an account on the
              Service. We do not knowingly collect information from or direct
              any of our content specifically to children under 16. If we learn
              or have reason to suspect that you are a user who is under the age
              of 16, we will unfortunately have to close your account. Please
              see our <a href="/Terms">Terms and Conditions</a> for information
              about account termination.
            </p>

            <h3>Uses made of information</h3>
            <p>
              We will only use information held about you for the following
              purposes:
            </p>
            <ul>
              <li>To understand and improve the Service we provide to you.</li>
              <li>
                To ensure that content from our Service is presented in the most
                effective manner for you and for your computer.
              </li>
              <li>
                To communicate with you by responding to your requests and
                sending you emails and messages about invoicing and account
                management.
              </li>
              <li>
                To administer your account and keep track of billing and
                payments.
              </li>
              <li>
                To keep the Service secure by investigating and preventing abuse
                and fraud.
              </li>
              <li>
                To carry out our obligations arising from any contracts entered
                into between you (or your business/employer) and us and to
                provide you with the information, products and services that you
                (or your business/employer) request from us.
              </li>
              <li>
                To provide you with information about other goods and services
                we offer that are similar to those that you have already
                purchased or enquired about.
              </li>
              <li>
                To notify you about changes to our Service. For internal
                operations, including troubleshooting, data analysis, testing,
                research, statistical and survey purposes.
              </li>
              <li>
                To allow you to participate in interactive features of our
                Service, when you choose to do so.
              </li>
              <li>
                To measure or understand the effectiveness of advertising we
                serve to you and others, and to deliver relevant advertising to
                you.
              </li>
              <li>
                To make suggestions and recommendations to you and other users
                of our Service about services that may interest you or them.
              </li>
              <li>To comply with our legal and regulatory obligations.</li>
            </ul>

            <p>We will not sell or rent your personal data to anyone.</p>
            <p>
              We may process your personal data without your knowledge or
              consent, in compliance with the above rules, where this is
              required or permitted by law.
            </p>

            <h3>Disclosure of your personal data</h3>

            <p>
              We may share your personal data with the following third parties:
            </p>
            <ul>
              <li>
                any member of our group, which means our subsidiaries, our
                ultimate holding company and its subsidiaries, as defined in
                section 1159 of the UK Companies Act 2006.
              </li>
              <li>
                any entity to which we sell our business or assets (or a
                substantial part of the same).
              </li>
              <li>
                any entity or regulator to which we are under a legal duty to
                disclose or share your Personal Data.
              </li>
              <li>
                our professional advisors, such as our lawyers, accountants and
                tax advisors.
              </li>
              <li>
                We also engage certain third party service providers to supply
                the hardware infrastructure, storage and associated services for
                us to provide, improve and optimise the Service. All Personal
                Data submitted to third parties will be either anonymised or
                encrypted using SSL technology, and such service providers will
                only use your Personal Data in accordance with our instructions.
              </li>
            </ul>

            <h3>Marketing Communications</h3>

            <p>
              <strong>Consent.</strong> We will only use your name and email
              address to send marketing communications to you, where you have
              <strong>"opted in"</strong> to receive such communications or
              where we are entitled to send you information about our similar
              goods and services under applicable law. You may elect to stop
              receiving our marketing emails at any time by following the
              unsubscribe instructions included in such emails.
            </p>
            <p>
              We send push notifications from time to time in order to update
              you about any Service updates, events and promotions we may be
              running. If you no longer wish to receive these communications,
              please disable these in the settings on your device. If you change
              your mind about being contacted in the future, please click on the
              opt out options included in emails or the Service and we will
              remove you from our mailing lists.
            </p>

            <h3>Protection of your information</h3>
            <p>
              Where we have given you (or you have chosen) a password which
              enables you to access certain parts of the Service, you are
              responsible for keeping this password confidential. We ask you not
              to share any password with anyone.
            </p>
            <p>
              Unfortunately, the transmission of information via the Internet is
              not completely secure. Although we will do our best to protect
              your Personal Data, we cannot guarantee the security of your data
              transmitted to our Service; any transmission is at your own risk.
              Once we have received your information, we will use strict
              procedures and security features to try to prevent unauthorised
              access.
            </p>

            <h3>Links to other websites</h3>
            <p>
              The Service may contain links to and from third party websites of
              our partners, networks, advertisers and affiliates. If you follow
              a link to any of these websites, please note that these websites
              have their own privacy policies and we do not accept any
              responsibility or liability for the privacy practices or content
              of these websites. You are responsible for checking the privacy
              policy of any third party websites we link to.
            </p>

            <h3>
              Where we store your Personal Data - for EU and UK Customers only
            </h3>
            <p>
              Our Service is global and your information including the Personal
              Data that we collect from you may be transferred to, and stored
              at, a destination outside the European Economic Area ("EEA"),
              Switzerland or the UK. It may also be processed by staff operating
              outside the EEA, Switzerland or the UK who work for us or for one
              of our suppliers. Such staff may be engaged in, among other
              things, the provision of support services. By submitting your
              Personal Data, you agree to this transfer, storing or processing
              outside of the EEA, Switzerland or the UK. We will take all steps
              reasonably necessary to ensure that your data is treated securely
              and in accordance with this Privacy Policy.
            </p>
            <p>
              We always ensure that your information is only transferred in full
              accordance with applicable data protection law. In particular,
              this means that your information will only be transferred to a
              country that provides an adequate level of protection (for
              example, because the European Commission or the UK or Swiss data
              protection authorities have determined that a country provides an
              adequate level of protection) or the recipient is bound by
              standard contractual clause according to conditions provided by
              the European Commission or applicable laws in Switzerland or the
              UK.
            </p>
            <p>
              Our Service is accessible via the Internet and may potentially be
              accessed by any user around the world. Other users may access the
              Service from outside the EEA, Switzerland or the UK. This means
              that where you chose to upload your data to the Service, it could
              be accessed from anywhere around the world and therefore a
              transfer of your data outside of the EEA, Switzerland or the UK
              may be deemed to have occurred. You consent to such transfer of
              your data for and by way of this purpose.
            </p>

            <h3>Your rights</h3>
            <p>
              You have the following rights in relation to your Personal Data:
            </p>
            <p>
              <strong>Access</strong> to information. You have the right to
              access information held about you.
            </p>
            <p>
              <strong>Right to deletion, rectification and data export.</strong>
              We permit you to delete, rectify and export information you have
              provided to us in a structured, machine readable and commonly used
              format, subject to the conditions of our Terms and Conditions and
              our legal obligations set out below.
            </p>
            <p>
              <strong>Restriction or Objection to processing.</strong> You have
              the right to request a restriction on or object to our processing
              of your Personal Data.
            </p>
            <p>
              <strong>Withdraw Consent.</strong> You have the right to withdraw
              your consent to the processing of your Personal Data at any time.
            </p>
            <p>
              You can make a request in relation to any of the above rights by
              contacting us as set out at the end of this Privacy Policy. We
              will respond to such queries in accordance with the provisions of
              applicable data protection law.
            </p>

            <h3>Data Retention</h3>
            <p>
              We retain Personal Data for as long as we provide the Services to
              you or your account remains open. However, we may keep some data
              after your account is closed or you cease using the Service, for
              the purposes set out below.
            </p>
            <p>
              After you have closed your account we may retain Personal Data
              where reasonably necessary to comply with our legal obligations
              (including law enforcement requests), meet regulatory
              requirements, maintain security, prevent fraud and abuse, resolve
              disputes, enforce our Terms and Conditions, offer new features you
              may be interested in, or fulfil your request to
              <strong>"unsubscribe"</strong> from further messages from us. If
              none of these obligations apply we will delete Personal Data
              within 12 months of your account being closed.
            </p>

            <h3>Complaints</h3>
            <p>
              If you have any complaints about our use of your information
              please contact us as set out at the end of this Privacy Policy or
              for individuals located in the EU, our EU representative is:
            </p>

            <p>
              Email:
              <a href="mailto:mpzmail@datarep.com">mpzmail@datarep.com</a>
            </p>
            <p>
              Online webform:
              <a
                href="https://www.datarep.com/data-request"
                target="_blank"
                rel="noopener noreferrer"
                >www.datarep.com/data-request</a
              >
            </p>
            <p>
              Addresses:
              <a href="/gdprcontactlocations">See GDPR Contact Locations</a>
            </p>
            <p>
              You also have the right to contact your local data protection
              supervisory authority:
            </p>
            <p>
              For individuals located in the UK: The Information Commissioner's
              Office (<strong>"ICO"</strong> at Wycliffe House, Water Lane,
              Wilmslow, Cheshire. SK9 5AF, England.
            </p>
            <p>
              For all other individuals: Your local data protection supervisory
              authority in the country in which you are located.
            </p>

            <h3>Changes to our Privacy Policy</h3>
            <p>
              We reserve the right to change this Privacy Policy at any time and
              any amended Privacy Policy will be posted on our websites and
              notified to you by email, where appropriate. Please check back
              frequently to see any updates or changes to our Privacy Policy.
              This Privacy Policy was last updated on 14 December 2021 and
              replaces any other Privacy Policy previously applicable from this
              date.
            </p>

            <h3>Contact</h3>
            <p>
              Questions, comments and requests regarding this Privacy Policy are
              welcomed and should be forwarded by email to:
              <a href="mailto:support@mpzmail.com">support@mpzmail.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "PrivacyPolicy",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    SiteFooter,
    TermsNav,
  },
  data() {
    return {
      meta: {
        title: "Privacy Policy - Transpond",
        description: "Take a look at the Transpond Platform Privacy Policy",
      },
    };
  },
};
</script>
