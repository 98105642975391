<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="Sub Processor List"> </PageHeader>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1">
          <div class="longform">
            <div class="terms-table">
              <table cellPadding="10" cellSpacing="0">
                <thead>
                  <th>Sub-processor</th>
                  <th>Purpose</th>
                  <th>Data</th>
                  <th>Location</th>
                  <th>Transfer Mechanism</th>
                  <th>Supplemental Measures</th>
                  <th>Contact Details</th>
                </thead>
                <tbody>
                  <tr>
                    <th>Amazon Web Services, Inc</th>
                    <td>Hosting services</td>
                    <td>
                      Customer entered data (contacts, cases, opportunities,
                      tasks, history, configuration), application logs
                    </td>
                    <td>USA</td>
                    <td>
                      <a
                        href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000TOWQAA4&status=Active"
                        target="_blank"
                        >DPF</a
                      >
                    </td>
                    <td>
                      Encryption of data in transit and at rest.<br />
                      Encryption key stored in the EU.<br />
                      <a
                        href="https://aws.amazon.com/blogs/security/aws-gdpr-data-processing-addendum/"
                        target="_blank"
                        >DPA</a
                      ><br />
                      <a
                        href="https://aws.amazon.com/compliance/eu-data-protection/"
                        target="_blank"
                        >Security</a
                      >
                    </td>
                    <td>
                      AWS Compliance contact us
                      <br />
                      410 Terry Avenue North, Seattle, WA 98109-5210, USA
                    </td>
                  </tr>
                  <tr>
                    <th>Digital Ocean LLC</th>
                    <td>Hosting Services</td>
                    <td>
                      Customer entered data (account details, contacts, send
                      data)
                    </td>
                    <td>USA</td>
                    <td>
                      <a
                        href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000TQNgAAO&status=Active"
                        target="_blank"
                        >DPF</a
                      >
                    </td>
                    <td>
                      Digital Ocean takes the following technical and
                      contractual measures to protect data:
                      <br />
                      <a
                        href="https://www.digitalocean.com/legal/data-processing-agreement/"
                        target="_blank"
                        >DPA</a
                      >
                    </td>
                    <td>
                      <a href="mailto:privacy@digitalocean.com"
                        >privacy@digitalocean.com</a
                      >. <br /><br />
                      101 Avenue of the Americas 10th Floor New York, NY 10013
                      USA
                    </td>
                  </tr>
                  <tr>
                    <th>Helpscout Inc.</th>
                    <td>Help desk services for customer support queries</td>
                    <td>Customer emails</td>
                    <td>USA</td>
                    <td>
                      <a
                        href="https://www.helpscout.com/company/legal/dpa/"
                        target="_blank"
                        >SCCs</a
                      >
                    </td>
                    <td>
                      Data is encrypted at rest, including 3rd party keys stored
                      by Apps.
                      <br />
                      <a
                        href="https://www.helpscout.com/company/legal/dpa/"
                        target="_blank"
                        >SCCs</a
                      >
                    </td>
                    <td>
                      <a href="mailto:dpa@helpscout.com">dpa@helpscout.com</a>
                      <br />
                      100 City Hall Plaza, 5th Floor; Boston, MA 02108, USA
                    </td>
                  </tr>
                  <tr>
                    <th>Akamai Technologies International AG (Linode LLC)</th>
                    <td>Hosting services</td>
                    <td>
                      Email files containing recipient and sender information
                      e.g. email addresses, custom field data, tracking data.
                    </td>
                    <td>USA</td>
                    <td>
                      <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt0000000Gn4RAAS&status=Active" target="_blank"
                        >DPF</a
                      >
                    </td>
                    <td>
                      Security measures to protect data:
                      <br />
                      <a
                        href="https://www.linode.com/legal-dpa/"
                        target="_blank"
                        >DPA</a
                      ><br />
                      <a
                        href="https://www.linode.com/legal-security/"
                        target="_blank"
                        >Security</a
                      ><br />
                    </td>
                    <td>
                      <a href="mailto:privacy@linode.com">privacy@linode.com</a>
                      <br /><br />
                      249 Arch Street 19106 Philadelphia, Pennsylvania, USA
                    </td>
                  </tr>

                  <tr>
                    <th>WideFM t/a Juice Broadband</th>
                    <td>Hosting services</td>
                    <td>
                      Used for email server hosting. Emails scheduled via the Transpond platform are sent through servers hosted by WideFM.
                    </td>
                    <td>UK</td>
                    <td>
                      Encryption
                    </td>
                    <td>
                      <a href="https://www.juicebroadband.co.uk/privacy-policy/" target="_blank">Security</a>
                    </td>
                    <td>
                      WideFM t/a Juice Broadband, Unit 6 The Concept Centre, Innovation Close, Poole, Dorset, BH12 4QD
                    </td>
                  </tr>

                  <tr>
                    <th>Google Inc.</th>
                    <td>Email Services</td>
                    <td>
                      Content of communications between a customer and Capsule support. Data subject emails.
                    </td>
                    <td>USA</td>
                    <td>
                      <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2zt000000001L5AAI&status=Active" target="_blank">DPF</a>
                    </td>
                    <td>
                      Encryption of data in transit and at rest for Google Cloud Platform and Google Workspace.<br/>
                      External Encryption Key storage outside of Google‘s technical infrastructure. <br/>
                      <a href="https://cloud.google.com/terms/data-processing-terms" target="_blank">DPA</a><br/>
                      <a href="https://workspace.google.com/terms/dpa_terms.html#appendix-2" target="_blank">Data Processing Amendment</a>
                      <a href="https://services.google.com/fh/files/misc/safeguards_for_international_data_transfers_with_google_cloud.pdf" target="_blank">Data Transfer Mechanisms</a>
                    </td>
                    <td>
                      <a href="https://cloud.google.com/" target="_blank">https://cloud.google.com/</a><br/>
                      Googleplex. 1600 Amphitheatre Pkwy, Mountain View, CA 9404
                    </td>
                  </tr>

                  <tr>
                    <th>CapsuleCRM Inc.</th>
                    <td>Customer support services</td>
                    <td>
                      Customer name, Content of communications between a customer and Capsule support
                    </td>
                    <td>USA</td>
                    <td>
                      <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?id=a2z3d000000MSGUAA4&status=Active" target="_blank">DPF</a>
                    </td>
                    <td>
                      Data is encrypted at rest and in transit<br/>
                      <a href="https://capsulecrm.com/dpa/" target="_blank">DPA</a><br/>
                      <a href="https://capsulecrm.com/security/" target="_blank">Security</a>
                    </td>
                    <td>
                      <a href="mailto:support@capsulecrm.com">support@capsulecrm.com</a><br/>
                      11845 W Olympic Blvd, Suite 1100, West Tower, 11th Floor, Los Angeles. CA90064
                    </td>
                  </tr>

                  <tr>
                    <th>Zestia Limited</th>
                    <td>Customer support services</td>
                    <td>
                      Customer name, Content of communications between a customer and Capsule support.
                    </td>
                    <td>UK</td>
                    <td>
                      SCCs
                    </td>
                    <td>
                      Data is encrypted at rest and in transit<br/>
                      <a href="https://capsulecrm.com/dpa/" target="_blank">DPA</a><br/>
                      <a href="https://capsulecrm.com/security/" target="_blank">Security</a>
                    </td>
                    <td>
                      <a href="mailto:support@capsulecrm.com">support@capsulecrm.com</a><br/>
                      20 Dale Street, Manchester, M1 1EZ
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "SubProcessors",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    SiteFooter,
    TermsNav,
  },
  data() {
    return {
      meta: {
        title: "Sub Processors List - Transpond",
        description: "Take a look at the Transpond Sub Processors List",
      },
    };
  },
};
</script>
