<template>
  <svg :class="getClass" width="176" height="47" viewBox="0 0 176 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.2778 6.92979V20.3283H6.60859V6.92979H0.59375V38.9133H6.60859V25.9499H19.2778V38.9133H25.2926V6.92979H19.2778Z" fill="black" fill-opacity="0.25"/>
    <path d="M45.6081 28.4749C45.6081 31.1213 43.3564 33.2666 40.5789 33.2666C37.8013 33.2666 35.5496 31.1213 35.5496 28.4749V14.8929H29.7832V28.4749C29.7832 34.1501 34.6119 38.7508 40.5684 38.7508C46.525 38.7508 51.3537 34.1501 51.3537 28.4749V14.8929H45.6081V28.4749Z" fill="black" fill-opacity="0.25"/>
    <path d="M88.331 16.2919C88.331 13.508 90.2641 12.6254 92.3818 12.6254C94.0885 12.6254 96.3433 13.8627 97.8176 15.3639L101.594 11.1242C99.709 8.69497 95.8846 7.01495 92.7541 7.01495C86.4992 7.01495 81.9749 10.5055 81.9749 16.2919C81.9749 27.0276 95.7476 23.6221 95.7476 29.6299C95.7476 31.483 93.8592 33.1204 91.6968 33.1204C88.2864 33.1204 87.1813 31.5312 85.6146 29.8512L81.4238 34.003C84.1045 37.1389 87.4107 38.7309 91.3721 38.7309C97.3143 38.7309 102.095 35.1978 102.095 29.6753C102.095 17.7563 88.3221 21.4597 88.3221 16.2919" fill="black" fill-opacity="0.25"/>
    <path d="M173.535 33.6596C170.127 33.6596 169.159 32.2549 169.159 30.1038V20.5828H174.455V15.7584H169.159V9.3931L163.312 11.8933V31.2872C163.312 36.2478 166.905 38.7479 171.831 38.7479C172.604 38.7613 173.376 38.7024 174.136 38.572L175.563 33.5688C174.92 33.6114 174.181 33.654 173.535 33.654" fill="black" fill-opacity="0.25"/>
    <path d="M67.5574 15.0518C64.701 15.0518 62.7083 15.8407 60.7812 17.6427V7.20509H55.0059V26.5508C55.0059 33.793 60.5012 38.7536 66.6757 38.7536C73.5263 38.7536 79.5519 33.705 79.5519 26.9055C79.5519 20.1911 74.0059 15.0546 67.5574 15.0546V15.0518ZM67.5216 33.2169C63.9026 33.2169 60.9689 30.4217 60.9689 26.9736C60.9689 23.5256 63.9026 20.7303 67.5216 20.7303C71.1406 20.7303 74.0744 23.5256 74.0744 26.9736C74.0744 30.4217 71.1406 33.2169 67.5216 33.2169Z" fill="black" fill-opacity="0.25"/>
    <path d="M130.267 26.6161C130.267 19.8052 124.253 14.768 117.391 14.768C111.216 14.768 105.721 19.7286 105.721 26.9708V46.3278H111.496V35.876C113.42 37.6752 115.416 38.467 118.269 38.467C124.718 38.467 130.264 33.3333 130.264 26.6161H130.267ZM124.798 26.548C124.798 29.996 121.864 32.7913 118.245 32.7913C114.626 32.7913 111.693 29.996 111.693 26.548C111.693 23.0999 114.626 20.3047 118.245 20.3047C121.864 20.3047 124.798 23.0999 124.798 26.548Z" fill="black" fill-opacity="0.25"/>
    <path d="M148.232 15.6197V10.1635C149.78 9.47611 150.773 8.00148 150.781 6.37702V6.24967C150.774 3.93439 148.801 2.05903 146.365 2.05281H146.231C143.795 2.05903 141.822 3.93439 141.815 6.24967V6.37702C141.823 8.00148 142.815 9.47611 144.364 10.1635V15.6197C142.183 15.9368 140.128 16.7959 138.409 18.1101L122.646 6.45343C122.758 6.0689 122.818 5.67232 122.824 5.27333C122.828 2.65851 120.6 0.536244 117.849 0.533115C115.098 0.529993 112.865 2.64719 112.861 5.26201C112.858 7.87683 115.086 9.9991 117.837 10.0022C118.702 9.99917 119.552 9.77955 120.299 9.36548L135.798 20.8326C132.941 24.9291 133.017 30.2712 135.991 34.292L131.275 38.7746C130.896 38.6601 130.502 38.599 130.104 38.5935C128.448 38.5935 126.956 39.5416 126.322 40.9956C125.688 42.4497 126.038 44.1234 127.209 45.2363C128.38 46.3491 130.141 46.6821 131.671 46.0798C133.201 45.4775 134.199 44.0586 134.199 42.4847C134.193 42.1071 134.129 41.7323 134.008 41.3726L138.674 36.938C142.847 39.9673 148.56 40.2148 153.006 37.5587C157.451 34.9026 159.693 29.9018 158.63 25.0146C157.566 20.1275 153.421 16.3821 148.232 15.6197ZM146.299 33.5675C142.734 33.5675 139.844 30.8206 139.844 27.4321C139.844 24.0436 142.734 21.2967 146.299 21.2967C149.865 21.2967 152.755 24.0436 152.755 27.4321C152.755 30.8206 149.865 33.5675 146.299 33.5675Z" fill="black" fill-opacity="0.25"/>
  </svg>
</template>

<script>
export default {
  name: "CapsuleLogo",
  props: {
    class: {
      type: String
    }
  },
  computed: {
    getClass() {
      return this.class;
    }
  }
}
</script>