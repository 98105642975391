<template>
  <header class="page-header" :class="[{ 'page-header--dark': theme == 'dark'}, classIn]">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-10">
          <h1 class="mb-5" v-html="title"></h1>
        </div>
        <div class="col-xs-12 col-sm-7">
          <h6 v-if="subtitleText">
            {{ subtitleText }}
          </h6>
        </div>

        <slot name="pageLinks"></slot>

        <slot name="image"></slot>

        <slot></slot>
        
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: "PageHeader",
  components: {
  },
  props: {
    class: {
      type: String
    },
    title: {
      type: String,
      required: true,
    },
    subtitleText: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    theme: {
      type: String,
    },
  },
  data(){
    return{
      classIn: this.class
    }

  }
};
</script>
