<template>
<footer class="footer footer--blog">
  <div class="container-fluid">
    <div class="row between-sm pb-16 pt-16">
      <div class="footer__legal mb-4 mr-4 mb-sm-0">© 2022 Transpond. All Rights Reserved.</div>
      <div class="footer__legal-links">
        <router-link class="footer__legal-link" to="/terms">Terms</router-link>
        <router-link class="footer__legal-link" to="/privacy">Privacy</router-link>
      </div>
    </div>
  </div>
</footer>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      showBanner: true,
    };
  },
  ///component has mounted
  created() {
    //see if they have a cookie
    let hasCookie = this.getCookie("mpz-cookies-ok");
    if (hasCookie) {
      this.showBanner = false;
    }
  },

  //functions
  methods: {
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    turnBannerOff() {
      const d = new Date();
      d.setTime(d.getTime() + 36135 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = "mpz-cookies-ok=1; " + expires + ";path=/";
      this.showBanner = false;
    },
    // changeCurrency(newCurr) {
    //   this.currency = newCurr;
    //   this.getPricing();
    // },
  },
};
</script>