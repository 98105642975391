<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <div>
    <navigation></navigation>

    <PageHeader title="GDPR Contact Locations"> </PageHeader>

    <div class="container-fluid">
      <div class="row mb-30">
        <div class="col-xs-12 col-md-3 mb-20">
          <TermsNav></TermsNav>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-1">
          <div class="longform">
            <p>
              Please ensure requests are addressed to 'DataRep' and refer to
              MPZMail Limited in the correspondence.
            </p>

            <div class="terms-table terms-table--2-col">
              <table>
                <thead>
                  <th>Country</th>
                  <th>Address</th>
                </thead>
                <tbody>
                  <tr>
                    <th>Austria</th>
                    <td>
                      DataRep, City Tower, Brückenkopfgasse 1/6. Stock, Graz,
                      8020, Austria
                    </td>
                  </tr>
                  <tr>
                    <th>Belgium</th>
                    <td>
                      DataRep, Rue des Colonies 11, Brussels, 1000
                    </td>
                  </tr>
                  <tr>
                    <th>Bulgaria</th>
                    <td>
                      DataRep, 132 Mimi Balkanska Str., Sofia, 1540, Bulgaria
                    </td>
                  </tr>
                  <tr>
                    <th>Croatia</th>
                    <td>
                      DataRep, Ground &amp; 9th Floor, Hoto Tower, Savska cesta
                      32, Zagreb, 10000, Croatia
                    </td>
                  </tr>
                  <tr>
                    <th>Cyprus</th>
                    <td>
                      DataRep, Victory House, 205 Archbishop Makarios Avenue,
                      Limassol, 3030, Cyprus
                    </td>
                  </tr>
                  <tr>
                    <th>Czech Republic</th>
                    <td>
                      DataRep, Platan Office, 28. Října 205/45, Floor 3&4, Ostrava, 70200, Czech Republic
                    </td>
                  </tr>
                  <tr>
                    <th>Denmark</th>
                    <td>DataRep, Lautruphøj 1-3, Ballerup, 2750, Denmark</td>
                  </tr>
                  <tr>
                    <th>Estonia</th>
                    <td>
                      DataRep, 2nd Floor, Tornimae 5, Tallinn, 10145, Estonia
                    </td>
                  </tr>
                  <tr>
                    <th>Finland</th>
                    <td>
                      DataRep, Luna House, 5.krs, Mannerheimintie 12 B,
                      Helsinki, 00100, Finland
                    </td>
                  </tr>
                  <tr>
                    <th>France</th>
                    <td>DataRep, 72 rue de Lessard, Rouen, 76100, France</td>
                  </tr>
                  <tr>
                    <th>Germany</th>
                    <td>
                      DataRep, 3rd and 4th floor, Altmarkt 10 B/D, Dresden,
                      01067, Germany
                    </td>
                  </tr>
                  <tr>
                    <th>Greece</th>
                    <td>DataRep, 24 Lagoumitzi str, Athens, 17671, Greece</td>
                  </tr>
                  <tr>
                    <th>Hungary</th>
                    <td>
                      DataRep, President Centre, Kálmán Imre utca 1, Budapest,
                      1054, Hungary
                    </td>
                  </tr>
                  <tr>
                    <th>Iceland</th>
                    <td>DataRep, Kalkofnsvegur 2, 101 Reykjavík, Iceland</td>
                  </tr>
                  <tr>
                    <th>Ireland</th>
                    <td>
                      DataRep, The Cube, Monahan Road, Cork, T12 H1XY, Republic
                      of Ireland
                    </td>
                  </tr>
                  <tr>
                    <th>Italy</th>
                    <td>
                      DataRep, Viale Giorgio Ribotta 11, Piano 1, Rome, Lazio,
                      00144, Italy
                    </td>
                  </tr>
                  <tr>
                    <th>Latvia</th>
                    <td>
                      DataRep, 4th &amp; 5th floors, 14 Terbatas Street, Riga,
                      LV-1011, Latvia
                    </td>
                  </tr>
                  <tr>
                    <th>Liechtenstein</th>
                    <td>
                      DataRep, City Tower, Brückenkopfgasse 1/6. Stock, Graz,
                      8020, Austria
                    </td>
                  </tr>
                  <tr>
                    <th>Lithuania</th>
                    <td>
                      DataRep, 44A Gedimino Avenue, 01110 Vilnius, Lithuania
                    </td>
                  </tr>
                  <tr>
                    <th>Luxembourg</th>
                    <td>
                      DataRep, BPM 335368, Banzelt 4 A, 6921, Roodt-sur-Syre,
                      Luxembourg
                    </td>
                  </tr>
                  <tr>
                    <th>Malta</th>
                    <td>
                      DataRep, Tower Business Centre, 2nd floor, Tower Street,
                      Swatar, BKR4013, Malta
                    </td>
                  </tr>
                  <tr>
                    <th>Netherlands</th>
                    <td>
                      DataRep, Cuserstraat 93, Floor 2 and 3, Amsterdam, 1081
                      CN, Netherlands
                    </td>
                  </tr>
                  <tr>
                    <th>Norway</th>
                    <td>DataRep, C.J. Hambros Plass 2c, Oslo, 0164, Norway</td>
                  </tr>
                  <tr>
                    <th>Poland</th>
                    <td>
                      DataRep, Budynek Fronton ul Kamienna 21, Krakow, 31-403,
                      Poland
                    </td>
                  </tr>
                  <tr>
                    <th>Portugal</th>
                    <td>
                      DataRep, Torre de Monsanto, Rua Afonso Praça 30, 7th
                      floor, Algès, Lisbon, 1495-061, Portugal
                    </td>
                  </tr>
                  <tr>
                    <th>Romania</th>
                    <td>
                      DataRep, 15 Piaţa Charles de Gaulle, nr. 1-T, Bucureşti,
                      Sectorul 1, 011857, Romania
                    </td>
                  </tr>
                  <tr>
                    <th>Slovakia</th>
                    <td>
                      DataRep, Apollo Business Centre II, Block E / 9th floor,
                      4D Prievozska, Bratislava, 821 09, Slovakia
                    </td>
                  </tr>
                  <tr>
                    <th>Slovenia</th>
                    <td>
                      DataRep, Trg. Republike 3, Floor 3, Ljubljana, 1000,
                      Slovenia
                    </td>
                  </tr>
                  <tr>
                    <th>Spain</th>
                    <td>
                      DataRep, Calle de Manzanares 4, Madrid, 28005, Spain
                    </td>
                  </tr>
                  <tr>
                    <th>Sweden</th>
                    <td>
                      DataRep, S:t Johannesgatan 2, 4th floor, Malmo, SE - 211
                      46, Sweden
                    </td>
                  </tr>
                  <tr>
                    <th>Switzerland</th>
                    <td>
                      DataRep, Leutschenbachstrasse 95, ZURICH, 8050, Switzerland
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "GDPRContactLocations",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },

  data() {
    return {
      meta: {
        title: "GDPR Contact Locations - Transpond",
        description: "Take a look at the Transpond GDPR Contact Locations",
      },
    };
  },
};
</script>
