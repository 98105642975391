<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <navigation></navigation>

  <PageHeader title="Reseller Agreement"> </PageHeader>
  <div class="container-fluid">
    <div class="row mb-30">
      <div class="col-xs-12 col-md-3 mb-20">
        <TermsNav></TermsNav>
      </div>
      <div class="col-xs-12 col-md-6 col-md-offset-1">
        <div class="longform">
          <p>
            Please read this Reseller Agreement (the
            <strong>"Agreement"</strong>) carefully before accessing or using
            the Transpond Services (<strong>"Services"</strong>) provided
            through our website at
            <a
              href="https://transpond.io"
              target="_blank"
              rel="noopener noreferrer"
              >https://transpond.io</a
            >
            (<strong>"Website"</strong>).
          </p>

          <p>
            The Website is operated by Transpond Limited, a private limited
            company registered in England with company number 11234241 whose
            registered office is Level 1, 20 Dale Street, Manchester, M1 1EZ, UK
            (<strong>"we"</strong> or <strong>"us"</strong> or
            <strong>"our"</strong>). Our VAT number is 325273024. This Agreement
            applies to all Agencies who resell the Service.
          </p>

          <p>
            By clicking on the accept buttons relating to this Agreement, the
            Data Processing Agreement and the Privacy Policy, you agree to be
            legally bound by the terms of the Agreement, Data Processing
            Agreement and Privacy Policy as they may be modified and posted on
            our Website from time to time. In this Agreement,
            <strong>"you"</strong> refers to the entity you represent (the
            <strong>"Agency"</strong>).
          </p>

          <p>
            If you do not agree with the terms of this Agreement, Data
            Processing Agreement and Privacy Policy, you may not become a
            reseller or order any Services.
          </p>

          <p>
            The Company and the Agency are referred to herein individually as a
            <strong>"Party"</strong> and collectively as the
            <strong>"Parties"</strong>.
          </p>

          <h2>Recitals</h2>

          <p>
            WHEREAS, the Company provides certain online marketing products and
            services (the <strong>"Services"</strong>).
          </p>

          <p>
            WHEREAS, the Agency markets, sells and/or licenses products and
            services to its end-user customers (the
            <strong>"Customers"</strong>).
          </p>

          <p>
            WHEREAS, the Agency wishes to market, sublicense and provide the
            Services to its Customers and the Company wishes to appoint the
            Agency as a non-exclusive reseller of the Services pursuant to the
            terms and conditions of this Agreement.
          </p>

          <p>The Parties agree as follows.</p>

          <ol class="orderList">
            <li>
              <strong>Scope of Agreement</strong>
              <ul>
                <li>
                  <span style="text-decoration: underline"
                    >1.1: Direct Sale/ Sublicense of Services by Agency.</span
                  >
                  The Agency purchases licenses to access the Services from the
                  Company and shall resell such licenses to the Services to
                  Customers.
                </li>
              </ul>
            </li>
            <li>
              <strong>Term and Termination</strong>
              <ul>
                <li>
                  <span style="text-decoration: underline">2.1: Term.</span>
                  The term of this Agreement shall commence on signup (the
                  “Effective Date”) and continue for a period of one (1) month
                  (the “Initial Term”). Thereafter, this Agreement will
                  automatically renew for successive one (1) month terms, (each
                  a “Renewal Term”)(the Initial Terms and all Renewal Terms
                  together being the “Term”), unless either Party gives the
                  other written notice of termination at least thirty (30) days
                  before the end of the Initial Term or a Renewal Term. Such
                  termination notice shall be effective on the expiry of the
                  Initial Term or the current Renewal Term.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >2.2: Termination.</span
                  >
                  Either Party may terminate this Agreement immediately upon
                  giving written notice if: (i) either Party ceases to do
                  business for any reason; (ii) either party has a receiver or
                  administrator appointed over all or part of its assets; (iii)
                  either Party becomes subject to any bankruptcy, insolvency,
                  reorganization, liquidation or other similar proceedings in
                  any jurisdiction; (iv) the ownership or the shareholder
                  structure of either Party changes (“Acquisition”); or (v)
                  there is any other attempted assignment of this Agreement by
                  either Party without prior written approval of the
                  non-assigning Party. In the event of termination of this
                  Agreement by either Party under clauses (i), (ii), or (iii) ,
                  either Party may: (a) declare all amounts payable under this
                  Agreement immediately due and payable; (b) proceed to enforce
                  performance and/or recover damages; and /or (c) terminate this
                  Agreement.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >2.3: Termination for Breach.</span
                  >
                  Either Party may terminate this Agreement upon giving thirty
                  (30) days prior written notice to the other Party in the event
                  of a material breach of this Agreement by the other Party, if
                  the defaulting Party fails to cure such breach within the
                  thirty (30) days following notice of such breach from the
                  non-defaulting Party. Without limiting the generality of the
                  foregoing, each of the Parties expressly agrees that: (i) the
                  failure to make any payment due under this Agreement; (ii) the
                  Agency's failure to provide support services; (iii) the
                  Agency's misrepresentation of the Services or the Company's
                  brand or products; or (iv) the Agency's breaches of any
                  applicable laws ; shall constitute a material breach of this
                  Agreement.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >2.4: Effects of Termination.</span
                  >
                  (i) Any expiration or termination of this Agreement shall not
                  modify any rights or obligations of a Party which arose prior
                  to such expiration or termination; (ii) the Agency must return
                  all copies of the Services, source code, documents, materials
                  and confidential information of the Company, whether in
                  electronic form or hardcopy as well as other objects that it
                  has received within the context of this Agreement to the
                  Company; (iii) the Company may agree with the Agency to
                  continue to provide access to the Services for existing
                  Customers of the Agency for a period of up to one year
                  following termination, to ensure that such Customers are able
                  to continue to use the Services, subject to the Agency
                  continuing to pay the Company all fees due under this
                  Agreement in relation to such Customers; (iv) the Parties can
                  agree to transfer Customer agreements to the Company and the
                  Company shall then provide the Services to the Customer
                  directly at the Company's then current prices and pursuant to
                  the Company's then current Terms and Conditions, published at
                  <a
                    href="https://transpond.io/Terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://transpond.io/Terms</a
                  >, in return for receiving payment of all fees directly from
                  the Customer; and (v) the Agency shall cease the use of any
                  Company trademark, domain name, logo or trade name in
                  connection with the promotion or advertising of the Services,
                  and shall immediately eliminate from all its literature,
                  business stationery, publications, notices and advertisements
                  all representations of the Agency's appointment hereunder.
                </li>
              </ul>
            </li>
            <li>
              <strong>Appointment</strong>
              <ul>
                <li>
                  3.1: The Company grants the Agency a non-exclusive, worldwide
                  right during the Term to: market the Services to potential
                  Customers; (ii) to sell the Services to Customers; and (iii)
                  provide first level support services to Customers; as set out
                  in the terms of this Agreement, and subject to the Terms and
                  Conditions.
                </li>
                <li>
                  3.2: The prices that the Agency shall pay the Company for each
                  purchase of Services for resale to a Customer shall be those
                  set out in the current reseller price list of the Company on
                  the date of each purchase (the “Price List”) unless agreed
                  otherwise in a the reseller order form (“Order Form”).
                </li>
                <li>
                  3.3: The Agency acknowledges that: (i) its appointment under
                  this Agreement is non-exclusive and non-transferable; (ii) the
                  Company maintains the right to appoint other resellers
                  directly or indirectly, to sell, promote, market license or
                  support the Services worldwide; and (the Company retains the
                  right to sell, promote, market, license or support the
                  Services itself or through its group companies and affiliates
                  worldwide.
                </li>
                <li>
                  3.4: The Agency shall negotiate, conclude and perform
                  agreements with Customers for the purchase of Services in its
                  own name and on its own account. The Agency shall have full
                  responsibility for concluding sales of the Services with
                  Customers (“Sales”) and for providing first level support
                  services to Customers.
                </li>
                <li>
                  3.5: The Agency is not permitted to contact, refer or conclude
                  Sales with any, named customers that Company notifies the
                  Agency of in writing during the Term from time to time,
                  (“Excluded Customers”).
                </li>
                <li>
                  3.6: Nothing in this Agreement shall prevent the Agency from
                  selling Services together with or in conjunction with the
                  Agency's own products and services, including but not limited
                  to the right of the Agency to sell Services as part of a
                  larger project or as a part of an entire company website,
                  provided however that: (i) there is no transfer whatsoever of
                  either title or intellectual property rights or other similar
                  rights of any sort attached to the Services; and (ii) the
                  Company shall remain the exclusive and sole owner of all such
                  rights.
                </li>
                <li>
                  3.7: The Agency shall indemnify the Company in full for any
                  infringement of section 3.6 above.
                </li>
              </ul>
            </li>
            <li>
              <strong>Agency's Obligations</strong>
              <br />
              The Agency shall:
              <ul>
                <li>
                  4.1: Maintain at all times the facilities, resources,
                  inventory, personnel and experience to market and/or
                  sublicense the Services. In particular the Agency shall have
                  at least one person representing the Agency at all times
                  during the Term.
                </li>
                <li>
                  4.2: Be responsible for and use its best efforts to market
                  and/or sublicense the Services in a manner which reflects
                  favourably upon the Services and upon the good name, goodwill
                  and reputation of the Company.
                </li>
                <li>
                  4.3: Look after the business relationship with Customers which
                  shall include, providing first level support and maintenance
                  of the Services as set out in this Agreement.
                </li>
                <li>
                  4.4: Act in the best interests of the Company and with the due
                  diligence of any ordinary businessman and trader.
                </li>
                <li>
                  4.5: Market and/or sublicense the Services to Customers and
                  not permit Services to be further sublicensed or sold without
                  the express written consent of the Company.
                </li>
                <li>
                  4.6: Not make any representations or warranties with respect
                  to the Services that are inconsistent with those made by the
                  Company in its published literature for the Services or which
                  are false or misleading.
                </li>
                <li>
                  4.7: Conclude Sales in its own name with Customers using the
                  Agency's own local terms and conditions and price lists.
                </li>
                <li>
                  4.8: The Agreement executed with each Customer for a Sale
                  shall: (i) contain terms and conditions substantially similar
                  to, and no less protective of the Company's rights than, those
                  contained in the Terms and Conditions; and (ii) all Customers
                  shall agree to comply with the provisions of the authorised
                  user policy (“AUP”) published at:
                  <a
                    href="https://transpond.io/acceptableuse"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://transpond.io/acceptableuse</a
                  >.
                </li>
                <li>
                  4.9: Only request access from the Company for its registered
                  and paying Customers.
                </li>
                <li>
                  4.10: Not private label or brand the Services with any third party’s name, logo, or trademark other than the Agency’s. 
                </li>
                <li>
                  4.11: Ensure that business secrets that have been revealed or
                  made known to it are not forwarded to third parties or
                  otherwise published or made known to third parties.
                  Furthermore, the Agency agrees not to use such business
                  secrets for its own benefit. This obligation shall survive
                  termination of this Agreement and be effective for a period of
                  5 years following termination.
                </li>
                <li>
                  4.12: Comply with any instructions and/or guidelines provided
                  by the Company.
                </li>
                <li>
                  4.13: Keep Customer's data up to date and record such details
                  carefully.
                </li>
                <li>
                  4.14: Attend, as requested by the Company, a reasonable number
                  of Services training or other training seminars which may be
                  periodically offered by the Company, at the Company's sole
                  discretion, at no charge and at locations designated by the
                  Company. The Agency shall be responsible for any and all
                  travel and other expenses and/or costs incurred by the
                  Agency's personnel with respect to such seminars.
                </li>
                <li>
                  4.15: Train and maintain its staff at its own expense to be
                  conversant with the technical language and functionalities of
                  the Services and shall develop sufficient knowledge of the
                  industry, the Services and of products competitive with the
                  Services so as to be able to explain the benefits and
                  functions of the Services to Customers.
                </li>
                <li>
                  4.16: Advertise and promote the Services in an appropriate manner. The Agency shall not use the Company’s brand in any marketing activity without first obtaining the prior written approval of the Company. The Company shall not be required to provide any advertising or trade show support to the Agency.

                </li>
                <li>
                  4.17: Pay the Company all sums owed under this Agreement for
                  the provision of the Services.
                </li>
                <li>
                  4.18: Cover all of its own expenses incurred pursuant to this
                  Agreement.
                </li>
                <li>
                  4.19: Comply with all applicable international, national,
                  state/provincial, regional and local laws and regulations in
                  performing its duties hereunder and in any of its dealings
                  with respect to the Services.
                </li>
                <li>
                  4.20: Promptly inform the Company if any dispute arises
                  between the Agency and a Customer in respect of the Services
                  and comply with all reasonable instructions of the Company in
                  relation thereto.
                </li>
              </ul>
            </li>
            <li>
              <strong>Company's Obligations</strong><br />
              The Company shall:
              <ul>
                <li>
                  5.1: Grant the Agency the non-exclusive, non-transferable
                  right to conclude Sales of Services to Customers worldwide in
                  accordance with the terms of this Agreement and subject to the
                  provisions of the Terms and Conditions and AUP. Any reference
                  to “Customer” in the Terms and Conditions or AUP shall be
                  deemed to be a reference to the Agency.
                </li>
                <li>
                  5.2: The Company shall provide the Agency, at no charge, with
                  a reasonable supply of Services' literature, marketing
                  information, user documentation and manuals in the English
                  language. At its own expense and with the Company's prior
                  written approval, the Agency may translate such documentation
                  into other languages and reproduce and distribute such
                  translations solely for its Customers' internal use. The
                  Agency shall ensure that all applicable Company notices
                  (including copyright notices) are included in such
                  translations and shall return all documentation upon the
                  termination of this Agreement. Upon Company's request, the
                  Agency agrees to provide copies of all such translations to
                  the Company and grants the Company a non-exclusive, perpetual,
                  transferable license to use the translations with regard to
                  the Services.
                </li>
                <li>
                  5.3: Subject to the terms of this Agreement and for
                  demonstration purposes grants the Agency a non-exclusive and
                  non-transferable right to access and use the Services for the
                  Term of this Agreement.
                </li>
                <li>
                  5.4: Host the Services (where applicable) in accordance with
                  the Terms and Conditions.
                </li>
                <li>
                  5.5: Provide second level support to the Agency on behalf of
                  Customers as set out in this Agreement. The Company shall only
                  provide support directly to Customers if the Parties enter
                  into a separate written support and maintenance agreement and
                  the Agency pays the Company additional fees for such
                  additional professional services.
                </li>
                <li>
                  5.6: Provide support and assistance reasonably required by the
                  Agency to fulfil its obligations under this Agreement to the
                  best of its abilities.
                </li>
                <li>
                  5.7: Promptly inform the Agency if the Company has submitted a
                  quote to a Customer or a company with the Customer's group or
                  the Customer is an Excluded Customer.
                </li>
                <li>
                  5.8: Ensure that neither the Company nor any of its partners
                  shall contact Customers or potential new customers in
                  connection with selling or promoting the Services for a period
                  of 30 days after receiving a completed Order Form.
                </li>
                <li>
                  5.9: Forward all documents and materials that the Agency
                  reasonably requests in order to comply with its obligations
                  under the Agreement, free of charge. No title or ownership in
                  such documents and materials shall pass to the Agency and they
                  shall continue to be owned by the Company.
                </li>
              </ul>
            </li>
            <li>
              <strong>Intellectual Property Rights</strong>
              <ul>
                <li>
                  6.1: Solely during the Term of this Agreement, and in
                  furtherance of the purposes and subject to the terms of this
                  Agreement, the Company grants the Agency the non-exclusive,
                  non-transferable, limited right to use and display the
                  Company's trademarks, trade names, logos, and/or other
                  insignia (collectively, “Trademarks”) solely in connection
                  with Agency's marketing, sale, promotion and/or sublicense of
                  the Services and for no other purpose, provided that the
                  Company may, in its sole discretion, revise at any time and
                  from time to time such Trademarks. Such Trademarks cannot be
                  sublicensed. The Agency shall not delete or alter any
                  Trademarks which are affixed to the Services or related
                  documentation. The Agency shall refrain from any other direct
                  or indirect use or registration of Trademarks or similar
                  marks. Upon termination of this Agreement, the Agency shall
                  take all actions necessary to transfer and assign to the
                  Company any right, title or interest in and to any Trademarks
                  and shall immediately cease to use any and all Trademarks.
                </li>
                <li>
                  6.2: The Agency acknowledges the value of the goodwill
                  associated with the Trademarks and agrees that the Trademarks
                  are the sole and exclusive property of the Company. The Agency
                  agrees that it shall not assert any claim of ownership to the
                  Trademarks or otherwise interfere with the Company's sole and
                  exclusive rights to the Trademarks. All goodwill from use of
                  the Trademarks by the Agency shall inure to the benefit of the
                  Company and the Agency shall not take any action in derogation
                  of any of the rights of the Company in the Trademarks,
                  including but not limited to, challenging or assisting others
                  to challenge the Trademarks or any registration thereof. The
                  Agency shall not, during the Term or after termination of this
                  Agreement, use, register or attempt to register with any
                  agency or in any jurisdiction any of the Trademarks or any
                  mark confusingly similar therewith. The Agency shall market,
                  sublicense, and support the Services only under the
                  Trademarks, and not under any other trademark or logo. The
                  Agency agrees not to use the Trademarks with respect to any
                  products or materials not provided by the Company, or in any
                  way that mightsuggest that the Company and the Agency are not
                  separate and distinct entities. The Agency shall provide the
                  company access to and/or hard copies of all proposed uses of
                  the Company's Trademarks prior to such usage being made
                  public, for the Company prior review and approval. The Company
                  shall have the right to modify or alter the way in which the
                  Trademarks appear, in accordance with the terms of this
                  Agreement. In connection therewith, the Agency agrees that it
                  shall modify to the Company's satisfaction or cease the use of
                  any Trademark to which the Company, in its sole discretion,
                  may object.
                </li>
                <li>
                  6.3: The Company does not grant and the Agency acknowledges
                  that it shall have no right, license or interest in any of the
                  copyrights, patents, utility models, trademarks, service
                  marks, registered designs, moral rights, design rights
                  (whether registered or unregistered), technical information,
                  know-how, database rights, semiconductor topography rights,
                  business names and logos, computer data, generic rights,
                  proprietary information rights and all other similar
                  proprietary rights (and all applications and rights to apply
                  for registration or protection of any of the foregoing) as may
                  exist anywhere in the world, (“IPRs”). The Agency acknowledges
                  the exclusive right of the Company in and to all IPRs in the
                  Services and all other materials covered by this Agreement. No
                  title to or ownership of the IPRs contained in the Services or
                  any part of the Services or the Company's confidential
                  information is transferred to the Agency. The Company
                  acknowledges that the Services as well as all enhancements,
                  updates, modifications, local versions or any derivatives
                  thereof, and all IPRs and proprietary rights therein shall
                  remain the Company's property.
                </li>
                <li>
                  6.4: The Company grants the Agency a non-exclusive,
                  non-transferable license to use its IPRs in the Services
                  solely for the purposes of Sales of the Services pursuant to
                  terms of this Agreement during the Term.
                </li>
                <li>
                  6.5: The Agency may from time to time provide suggestions,
                  comments for enhancements or functionality or other feedback
                  (“Feedback”) to the Company. The Parties agree that such
                  Feedback shall be given voluntarily, and the Agency
                  acknowledges and agrees that the Company shall own all right,
                  title and interest in and to the Feedback, all developments
                  based upon such Feedback and all IPRs in and to the foregoing.
                  The Agency hereby assigns and does agree to assign to the
                  Company all right, title and interest it may have in and to
                  the Feedback and shall cooperate with the Company as
                  reasonably necessary in order to give full effect to such
                  assignment.
                </li>
              </ul>
            </li>
            <li>
              <strong>Fees, Prices and Discounts</strong>
              <ul>
                <li>
                  7.1: During the Term of this Agreement, the Agency may
                  purchase licenses to the Services from the Company at the
                  applicable fees and prices set forth in the Price List, or as
                  set out in an Order Form, less any applicable discounts. All
                  fees and prices are exclusive of all taxes and other charges,
                  including but not limited to, shipping, handling, insurance,
                  sales, use, value-added or other similar taxes, duties or
                  assessments.
                </li>
                <li>
                  7.2: The Company may modify the prices or discounts for any
                  Services under this Agreement at any time upon giving thirty
                  (30) days prior written notice to the Agency.
                </li>
                <li>
                  7.3: All Order Forms accepted by the Company before the
                  effective date of any price or fee modification shall be at
                  the price or fee in effect prior to such modification.
                  Thereafter, all Order Forms accepted by the Company shall be
                  at such modified price or fee.
                </li>
              </ul>
            </li>
            <li>
              <strong>Invoicing and Payment</strong>
              <ul>
                <li>
                  8.1: The Company shall invoice the Agency for the Services
                  ordered by the Agency at the prices and on the dates set out
                  in the Order Form. VAT or local sales tax shall be added if,
                  and as far as, the Company is obliged to pay VAT, local sales
                  tax or withholding taxes according to local applicable tax
                  laws.
                </li>
                <li>
                  8.2: All invoices are payable by the Agency within seven (7)
                  days of the date of each invoice. All amounts invoiced are
                  payable in the currency stated in the Order Form. The Agency
                  agrees to provide the Company any financial information from
                  time to time as may be reasonably requested by the Company.
                </li>
                <li>
                  8.3: Except for taxes based on the Company's net income, the
                  Agency shall pay any applicable sales, use, value-added or
                  other similar taxes, duties or assessments, or amounts levied
                  in lieu of such taxes, now or later imposed. Any claim for
                  exemption by the Agency shall be effective only after the
                  Company's receipt of all proper exemption forms.
                </li>
                <li>
                  8.4: The Company shall be entitled to charge interest on any
                  payment overdue by 7 days at the statutory rate and shall be
                  entitled to such reasonable costs as it incurs in the
                  collection of such overdue payments.
                </li>
              </ul>
            </li>
            <li>
              <strong>Orders</strong>
              <ul>
                <li>
                  <span style="text-decoration: underline">9.1: Orders.</span>
                  All orders of Services by the Agency from the Company shall be
                  made using the Order Form set out in Schedule 1 of this
                  Agreement which shall specify the quantity and description of
                  the Services, any applicable discounts, and name of the
                  Customer.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >9.2: Order Procedures.</span
                  >
                  All Order Forms submitted by the Agency are subject to
                  acceptance by the Company and Company shall have no liability
                  for non-acceptance of, or failure or delay in accepting any
                  Order Form. The Company shall acknowledge each Order Form,
                  pursuant to this section within ten (10) business days of its
                  receipt and specify delivery dates for access to the Services
                  in accordance with its then-current lead times. The Company
                  shall use reasonable efforts to meet proposed delivery dates
                  for access to the Services but shall not be liable for any
                  delivery delays. For the avoidance of doubt, no purchase
                  order, acknowledgment form, or other document or communication
                  shall amend the terms of this Agreement.
                </li>
                <li>
                  9.3: The Company reserves the right to discontinue the
                  manufacture, license or sale of any or all Services at any
                  time, and to refuse any orders for such discontinued Services
                  without any liability whatsoever to the Agency or any other
                  third party. The Company shall give the Agency at least ninety
                  (90) days advance notice of any intention to discontinue
                  Services. No such refusal or delay in delivery shall be deemed
                  a termination or breach of this Agreement.
                </li>
              </ul>
            </li>
            <li>
              <strong>License of Services.</strong>
              <ul>
                <li>
                  10.1: Subject to the terms of this Agreement, the Company
                  grants to the Agency a non-exclusive, non-transferable right
                  to access and use the Services during the Term, in object code
                  form only, solely for internal business purposes and solely in
                  conjunction with the addition of the Agency's software
                  applications, services and solutions approved by the Company,
                  to perform the following activities: market the Services,
                  demonstrate the Services to Customers, and provide first level
                  support to Customers. Such Services provided by the Company
                  shall not be resold, licensed or sublicensed to any third
                  party, except as specifically provided herein or in an Order
                  Form. Except as otherwise permitted above, the Agency's
                  internal use of any Services licensed hereunder shall be
                  subject to the Terms and Conditions.
                </li>
                <li>
                  10.2: The Agency acknowledges that the Services and their
                  structure, organization, and source code constitute valuable
                  trade secrets of the Company and its suppliers. Except as
                  expressly permitted in this Agreement, the Agency agrees that
                  it shall not, and shall not permit any third party, to: (i)
                  modify, adapt, alter, translate, or create derivative works
                  from the Services or the documentation; (ii) merge the
                  Services with other goods or services; (iii) sublicense, or
                  export (except as provided herein) the Services to any third
                  party; (iv) sell, provide for service bureau use, lease, rent,
                  loan, or otherwise transfer the Services or the documentation
                  to any third party; (v) solicit sales of, sell, distribute,
                  sublicense or otherwise transfer the Services; (vi) solicit
                  sales of, sell, distribute, sublicense or otherwise transfer
                  the Services to any person or entity that is not an end-user;
                  (vii) engage any third parties to solicit sales of, sell,
                  distribute, sublicense or otherwise transfer the Services;
                  (viii) solicit sales of, sell, distribute, sublicense or
                  otherwise transfer the Services to customers whom the Agency
                  knows, or has reason to believe, intend to resell the
                  Services; (ix) reverse engineer, decompile, disassemble, or
                  otherwise attempt to derive the source code for the Services,
                  provided, however, that if reproduction of the Services and
                  translation of their form are necessary to obtain the
                  information required to achieve the interoperability of the
                  Services with other programs, the Agency shall inform the
                  Company in writing accordingly and the Company shall notify
                  the Agency within thirty (30) days from receipt of such
                  request that the Agency will perform the work in order to
                  achieve such interoperability and assess a reasonable charge
                  for such work to the Agency, or authorize the Agency to
                  undertake those actions, but only to the extent required to
                  achieve the interoperability of the Services with other
                  programs; (x) remove or alter any copyright notices or other
                  notices included in the Services; (xi) use the Services to
                  conduct any competitive benchmarking tests or analysis; (xii)
                  copy, frame or mirror any part or content of the Services;
                  (xiii) use the Services to store or transmit malicious code,
                  infringing, libellous, or otherwise unlawful or tortious
                  material or to store or transmit material in violation of
                  third party privacy rights; (xiv) interfere with or disrupt
                  the integrity or performance of the Services or third party
                  data contained therein; (xv) attempt to gain unauthorized
                  access to the Services or its related systems or networks;
                  (xvi) access the Services in order to build a competitive
                  product or service; or (xvii) otherwise use or copy the
                  Services except as expressly permitted. Without the limiting
                  the foregoing, the restrictions on use of the Services set
                  forth in this section shall apply to any Services supplied to
                  the Company by its suppliers. The Agency shall be fully
                  responsible to the Company and its suppliers for compliance by
                  the Agency's employees, agents and consultants with the
                  foregoing license restrictions and all other terms of this
                  Agreement. The Company and the Agency shall mutually agree
                  upon additional terms, conditions and charges with respect to
                  any localization of the Services.
                </li>
                <li>
                  10.3: In the event the Agency sub-licenses the Services to the
                  Agency's Customers, the Company grants the Agency the
                  non-exclusive, non-transferable right, revocable right subject
                  to the terms of this Agreement, to sublicense access to the
                  Services to its Customers for their internal use only by means
                  of a written, non-exclusive, non-transferable, revocable
                  license agreement between the Agency and such Customer in a
                  form substantially similar to the Terms and Conditions. Such
                  license agreement must be signed by each Customer before the
                  Services are made accessible to such Customer. The Agency
                  shall promptly send a copy of each license agreement executed
                  by a Customer to the Company. The Agency shall make all
                  reasonable efforts to ensure enforcement of such license
                  agreements and in the event of a breach, the Agency shall
                  promptly notify the Company of such breach and will institute
                  legal action against such Customer if requested by the
                  Company.
                </li>
              </ul>
            </li>
            <li>
              <strong>Records and Audit</strong>
              <ul>
                <li>
                  11.1: If requested. within thirty (30) days after the end of
                  each calendar month during the Term of this Agreement, the
                  Agency shall provide written reports on the license and/or
                  sublicense of the Services for the prior month including,
                  without limitation, identity of Customers and the quantity of
                  Services.
                </li>
              </ul>
            </li>
            <li>
              <strong>Warranties</strong>
              <ul>
                <li>
                  12.1: Each Party warrants and represents that: (i) It has full
                  corporate power and authority to enter into this Agreement and
                  to perform the obligations required hereunder; (ii) The
                  execution and performance of its obligations under this
                  Agreement does not violate or conflict with the terms of any
                  other agreement to which it is a Party and is in accordance
                  with any applicable laws; and (iii) It shall respect all
                  applicable laws and regulations, governmental orders and court
                  orders, which relate to this Agreement.
                </li>
                <li>
                  12.2: The Company warrants to the Agency that it has the right
                  to licence the Services.
                </li>
                <li>
                  12.3: Except as expressly stated in this Agreement, all
                  warranties and conditions, whether expressed or implied by
                  statute, common law or otherwise (including but not limited to
                  satisfactory quality and fitness for purpose) are excluded to
                  the fullest extent permitted by law.
                </li>
              </ul>
            </li>
            <li>
              <strong>Confidentiality.</strong>
              <ul>
                <li>
                  13.1: Each Party agree to keep secret and not to disclose any
                  confidential information of the other Party (including without
                  limitation the terms of this Agreement) acquired hereunder or
                  in connection herewith except as authorised in writing by the
                  disclosing Party and shall keep and shall require its
                  officers, directors, and employees to keep confidential such
                  information. Confidential information shall include but not be
                  limited to all IPRs, trade secrets, know-how, technical,
                  operating, financial and other information relating to the
                  business, Services, prices, customers and suppliers of each
                  Party.
                </li>
                <li>
                  13.2: The provisions of any non-disclosure agreement entered
                  into by the Parties prior to the Effective Date of this
                  Agreement shall apply in addition to this section 13.
                </li>
                <li>
                  13.3: The obligation to keep such information confidential
                  shall continue in effect after the expiration or termination
                  of this Agreement.
                </li>
              </ul>
            </li>
            <li>
              <strong>Indemnity</strong>
              <ul>
                <li>
                  14.1: The Company shall defend at its own expense any action
                  against the Agency brought by a third party to the extent that
                  the action is based upon a claim that the Services infringe
                  any IPRs of a third party, and the Company will pay those
                  costs and damages finally awarded against the Agency in any
                  such action that are specifically attributable to such claim
                  or those costs and damages agreed to in a settlement of such
                  action. The foregoing obligations are conditioned on the
                  Agency: (i) notifying the Company promptly in writing of such
                  action; (ii) promptly giving the Company sole control of the
                  defence thereof and any related settlement negotiations; and
                  (iii) assisting and fully cooperating, at the Company's
                  reasonable request and expense in such defence.
                </li>
                <li>
                  14.2: If the Services become, or in the Company's opinion are
                  likely to become, the subject of an infringement claim, the
                  Company may, at its sole discretion and expense, either: (i)
                  procure for the Agency the right to continue using the
                  Services; (ii) replace or modify the Services so that they
                  become non-infringing; or (iii) terminate this Agreement, in
                  whole or in part, as appropriate, upon written notice to the
                  Agency and refund the Agency the prices and/or fees paid for
                  such Services upon such termination, computed according to a
                  sixty (60) month straight-line amortization schedule beginning
                  on the Effective Date. Notwithstanding the foregoing, the
                  Company will have no obligation under this section or
                  otherwise with respect to any infringement claim based upon
                  (a) any use of the Services not in accordance with this
                  Agreement; (b) any use of the Services in combination with
                  equipment, software, or data not supplied by the Company if
                  such infringement would have been avoided but for the
                  combination with other equipment, software or data; (c) any
                  use of any release of the Services other than the most current
                  release made available to the Agency; (d) any modification of
                  the Services by any person other than the Company or its
                  authorized agents or subcontractors; or (e) the Agency's
                  continued distribution of the Services subsequent to receipt
                  of notice of any claimed infringement.
                </li>
                <li>
                  14.3: The Agency agrees to indemnify and hold the Company
                  harmless from any claims, suits, proceedings, losses,
                  liabilities, damages, costs and expenses (inclusive of
                  reasonable legal fees) arising from: (i) the Agency's use or
                  distribution of Services (except to the extent this is covered
                  by the Company's indemnification of the Agency pursuant to
                  section 14.1); (ii) any breach of this Agreement by the Agency
                  (including, without limitation, any representation, warranty,
                  or covenant of the Agency hereunder); (iii) the marketing,
                  sale, license, sublicense and/or support and related
                  activities pursuant to this Agreement with respect to the
                  Services provided to Customer by the Agency; (iv) the failure
                  of the Agency to comply with all applicable laws, rules,
                  and/or regulations regarding the Services; and/or (v) the
                  negligence, misrepresentation, or error or omission on the
                  part of the Agency or any employee, agent or representative of
                  the Agency. Without limiting the generality of the foregoing,
                  the Agency shall be solely responsible for, and shall
                  indemnify and hold the Company harmless from, any claims based
                  upon warranties or representations made by the Agency or the
                  Agency's employees or agents which differ from or are
                  inconsistent with those made by the Company in this Agreement.
                  The Company shall have the right to participate, at its
                  expense, in the defence of any claim covered under this
                  section 14.3 with legal counsel of its own choosing.
                </li>
              </ul>
            </li>
            <li>
              <strong>Export Controls</strong>
              <ul>
                <li>
                  15.1: The Agency acknowledges that if the export of the
                  Services is subject to regulation by the United States and
                  United Nations which prohibits export or diversion of the
                  Services to certain countries and entities. The Agency shall
                  not export or re-export, directly or indirectly, any of the
                  Services to any prohibited or restricted countries or
                  entities. The Agency further agrees not to distribute or
                  supply the Services to any person if the Agency has reason to
                  believe that such person intends to export, re-export or
                  otherwise transfer the Services to, or use the Services in any
                  of such countries or for the benefit of such entities. The
                  Agency agrees to seek written assurances from its Customers as
                  may from time to time be requested by the Company. Without
                  limiting the foregoing, the Agency shall not commit any act
                  which would, directly or indirectly, violate any United
                  States, United Nations or local law, regulation, treaty or
                  agreement relating to the export or re-export of the Services.
                  At its expense, the Agency shall obtain any government
                  consents, authorizations, or licenses required for the Agency
                  to exercise its rights and to discharge its obligations under
                  this Agreement.
                </li>
              </ul>
            </li>
            <li>
              <strong>Limitation of Liability</strong>
              <ul>
                <li>
                  16.1: Neither party excludes or limits its liability to the
                  other for: (i) fraud; (ii) death or personal injury caused by
                  their negligent act or omission; (iii) wilful misconduct; or
                  (iv) any claim that cannot be excluded or limited under
                  applicable law.
                </li>
                <li>
                  16.2: Subject to clause 16.1 and, to the maximum extent
                  permitted by law, the Company shall have no liability for any
                  loss resulting from: (i) third party content or user content;
                  (ii) Company content, and in particular from the accuracy,
                  completeness or current status of Company content; (iii)
                  reliance on information contained or functionality provided
                  on, or through the Services; (iv) inability to gain access to
                  the Services or any part of it, or access is interrupted or
                  partial, or functions with errors, at any time; (v) any
                  failure by the Company to perform, or delay in performing, any
                  obligations, whether or not the Agency gives prior notice, if
                  and to the extent that the failure or delay is caused by Force
                  Majeure and the time for performance of any obligation, the
                  performance of which is so affected, will be extended
                  accordingly; or (vi) any breach of the AUP.
                </li>
                <li>
                  16.3: The Company shall not be liable to the Agency (whether
                  in contract, tort (including negligence), breach of statutory
                  duty or otherwise) misrepresentation or however arising out of
                  or in connection with this Agreement or for the use of the
                  Services for any loss of profits (whether categorised as
                  direct or indirect), loss of business opportunity, loss of
                  goodwill, loss of anticipated savings or benefits, or for any
                  type of indirect, special or consequential loss, even if that
                  loss or damage was reasonably foreseeable or the relevant
                  party was aware of the possibility of that loss or damage
                  arising.
                </li>
                <li>
                  16.4: Subject to clauses 16.1 to 16.3, the Company's total
                  liability in aggregate (whether in contract, tort or
                  otherwise) for any and all claims relating to or arising
                  directly or indirectly under this Agreement or based upon any
                  claim for indemnity or contribution shall be limited to the
                  amount paid by the Agency to the Company for the Services
                  included in an applicable Order Form(s) to which claims relate
                  in the 12 month period immediately prior to the date on which
                  any such claim arose. If the duration of the Agreement has
                  been less than 12 months, such shorter period shall apply.
                </li>
                <li>
                  16.5: The Agency shall not raise any claim arising out of or
                  in connection with this Agreement more than one year after the
                  date of the act or omission alleged to have caused the loss
                  claimed.
                </li>
                <li>
                  16.6: The Agency shall be liable for any breaches of this
                  Agreement, in particular for breaches of the AUP, caused by
                  the acts, omissions or negligence of any Customers or their
                  users who access or use the Services as if such acts,
                  omissions or negligence had been committed by the Agency
                  itself.
                </li>
                <li>
                  16.7: Where any sales tax or similar tax is applicable to a
                  Customer, the Company shall have no liability whatsoever to
                  the Agency for the correct invoicing of such tax, even when
                  the Agency uses the invoicing template provided within the
                  Services. The Agency hereby acknowledges its sole
                  responsibility for sending compliant tax invoices to
                  Customers.
                </li>
                <li>
                  16.8: The Company shall have no liability whatsoever for any
                  hardware or software used in connection with the Services,
                  which it does not own or sub-licence or directly control.
                </li>
                <li>
                  16.9: The Parties acknowledge and agree that in entering into
                  this Agreement, each had recourse to its own skill and
                  judgement and have not relied on any representation made by
                  the other, their employees or agents.
                </li>
              </ul>
            </li>
            <li>
              Data Protection
              <ul>
                <li>
                  17.1: Each Party undertakes to comply with its obligations
                  under relevant applicable data protection laws, principles and
                  agreements.
                </li>
                <li>
                  17.2: To the extent that personal data is processed when the
                  Agency or its Customers use the Services, the Parties
                  acknowledge that Company is a data processor and the Agency is
                  a data controller and the Parties shall comply with their
                  respective obligations under applicable data protection law
                  and the terms of the data protection agreement, (“DPA”)
                  published at
                  <a
                    href="https://transpond.io/reseller_dpa"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://transpond.io/reseller_dpa</a
                  >.
                </li>
                <li>
                  17.3: If a third party alleges infringement of its data
                  protection rights, the Company shall be entitled to take
                  measures necessary to prevent the infringement of a third
                  party's rights from continuing.
                </li>
                <li>
                  17.4: Where the Company collects and processes personal data
                  of the Agency or its Customers, as a data controller, when
                  providing the Services to the Agency or Customers, such
                  collection and processing shall be in accordance with
                  Company's privacy policy, published at:
                  <a
                    href="https://transpond.io/Privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    >https://transpond.io/Privacy</a
                  >, (“Privacy Policy”).
                </li>
              </ul>
            </li>
            <li>
              General.
              <ul>
                <li>
                  <span style="text-decoration: underline"
                    >18.1: Non-Exclusive.</span
                  >
                  The rights granted to the Parties under this Agreement are
                  non-exclusive.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.2: Assignment.</span
                  >
                  Neither Party shall have the right to assign or otherwise
                  transfer its rights or delegate its duties under this
                  Agreement without the express written consent of the other
                  Party. For purposes of this Agreement, an Acquisition of the
                  Agency shall constitute an assignment of this Agreement by the
                  Agency. Notwithstanding the forgoing, the Company may assign
                  this Agreement and its rights and obligations hereunder
                  (whether by operation of law, contract, or otherwise) without
                  the Agency's prior consent: (i) to any individual or business
                  entity controlling, controlled by or under common control with
                  the Company; (ii) in the event of a merger, consolidation or
                  the sale of all or substantially all of the Company's assets
                  or stock; and (iii) the Company may assign the right solely to
                  monies due or becoming due for financing purposes. Any
                  purported assignment in breach of this section shall be null
                  and void and a breach of this Agreement. Subject to the
                  foregoing, this Agreement shall be binding upon and inure to
                  the benefit of the Parties and their successors and assigns.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.3: Independent Contractor.</span
                  >
                  The Agency shall conduct its business under this Agreement as
                  an independent contractor and this Agreement creates no
                  relationship of employer and employee, principal and agent,
                  joint venture or any similar relationship. The Agency shall
                  not have, and shall not represent that it has, any power,
                  right or authority to bind the Company, or to assume or create
                  any obligation or responsibility, express or implied, on
                  behalf of the Company.
                </li>
                <li>
                  <span style="text-decoration: underline">18.4: Notice.</span>
                  All notices under this Agreement must be in writing. Notices
                  will be deemed given when: (i) delivered personally; (ii) five
                  (5) business days after having been sent by registered or
                  certified mail, return receipt requested, postage prepaid; or
                  (iv) two (2) business days after deposit with a commercial
                  overnight carrier, with written verification of receipt;
                  provided that a notice of address change shall only be
                  effective upon receipt.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.5: Governing Law.</span
                  >
                  This Agreement shall be governed by and construed in
                  accordance with the laws of England and Wales and the Parties
                  shall submit to the exclusive jurisdiction of the English
                  courts.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.6: Severability.</span
                  >
                  Should one or more of the provisions of this Agreement be
                  deemed to be invalid, illegal or unenforceable, the remaining
                  clauses shall remain unaffected. The Parties shall then agree
                  to substitute the invalid clause by a clause that is legally
                  and economically as far as possible comparable to the invalid
                  clause.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.7: Counterparts and Headings.</span
                  >
                  This Agreement may be executed in any number of counterparts,
                  each of which when executed and delivered shall be deemed to
                  be an original and all of which taken together shall
                  constitute one and the same instrument. Headings in this
                  Agreement are included for reference only and shall not
                  constitute a part of this Agreement for any other purpose. The
                  English language version of this Agreement shall be definitive
                  and shall control over any translation.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.8: Force Majeure.</span
                  >
                  Both Parties shall not be liable to the other for any loss,
                  injury, delay (except for any payment obligations) for
                  expenses or damages arising out of any cause or event not
                  within its reasonable control including, but not limited to:
                  riots, wars or hostilities between any nations; acts of
                  terrorism; Acts of God, fires, storms, floods or earthquakes;
                  strikes, labour disputes, epidemics, pandemics, quarantine,
                  vendor delays, or shortages or curtailments of raw materials,
                  labour, power or other utility services including access to
                  telecommunications facilities and the Internet; governmental
                  restrictions or trade disputes; manufacturing delays; or other
                  contingencies, (“Force Majeure”). However, if such
                  circumstances persist for more than 30 (thirty) days, the
                  non-defaulting Party may terminate this Agreement.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.9: Entire Agreement.</span
                  >
                  Excluding any non-disclosure entered into between the Parties
                  prior to the Effective Date, this Agreement and any documents
                  referred to in in constitutes the entire agreement and
                  understanding between the Parties and supersedes all prior and
                  contemporaneous agreements, negotiations, discussions,
                  representations and understandings between the Parties. The
                  Agency acknowledges that it has not relied upon any promise,
                  representation or statement of the Company except as expressly
                  set out herein.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.10: Prevails clause.</span
                  >
                  In the event of any inconsistency between the content of the
                  terms of this Agreement an Order Form, the Terms and
                  Conditions, the AUP, the DPA and the Privacy Policy, the main
                  terms of this Agreement shall prevail followed by the Order
                  Form, the Terms and Conditions, the AUP, the DPA and then the
                  Privacy Policy.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.11: Amendments.</span
                  >
                  No amendment or modification of any provision of this
                  Agreement shall be effective unless in writing and signed by a
                  duly authorized representative of each Party.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.12: No Waiver.</span
                  >
                  No failure or delay of either Party in exercising any right or
                  remedy under this Agreement shall operate as a waiver of such
                  right or remedy.
                </li>
                <li>
                  <span style="text-decoration: underline"
                    >18.13: No Third Party Rights.</span
                  >
                  Unless expressly stated in this Agreement, nothing contained
                  in this Agreement is intended to be enforceable by any third
                  party pursuant to any rights that such third party may have
                  under applicable law or otherwise and the Contracts (Rights of
                  Third Parties) Act 1999 is hereby specifically excluded.
                </li>
                <li>
                  <span style="text-decoration: underline">18.14Costs.</span>
                  Each Party shall pay its own legal, accountancy and other
                  costs arising out of and in connection with this Agreement.
                </li>
              </ul>
            </li>
          </ol>

          <h1>Schedule 1</h1>
          <h2>ORDER FORM</h2>

          <p><strong>THIS ORDER FORM</strong> is made between:</p>

          <p>
            <strong>MPZMail Limited</strong> a company registered in England and
            Wales with company number 11234241 with its registered offices at
            Level 1, 20 Dale Street, Manchester, M1 1EZ, England, (the
            <strong>"Company"</strong>),
          </p>

          <p>and</p>

          <p>the reseller, (the <strong>"Agency"</strong>).</p>

          <h2>Term and Customer</h2>

          <table cellPadding="10" cellSpacing="0" border="#000000">
            <tr>
              <td>Customer</td>
              <td>Reseller's customers for whom the Services are purchased</td>
            </tr>
            <tr>
              <td>Effective Date</td>
              <td>Signup of this Agreement</td>
            </tr>
            <tr>
              <td>Initial Term</td>
              <td>1 month starting from the Effective Date.</td>
            </tr>
            <tr>
              <td>Renewal Term</td>
              <td>1 month.</td>
            </tr>
            <tr>
              <td>Term</td>
              <td>Initial Term plus any Renewal Terms.</td>
            </tr>
          </table>

          <h2>Fees, Invoicing and Payment</h2>

          <p>
            The following Fees are payable for access and use of the Services by
            the agency for the Customers during the Term of this Order Form:
          </p>

          <table cellPadding="10" cellSpacing="0" border="#000000">
            <tr>
              <td>Amount(Agreed Currency)</td>
              <td>Invoicing</td>
              <td>Payment</td>
            </tr>
            <tr>
              <td>Service Fee</td>
              <td>
                20% off the MPZ pricing from the website for Agency
                customers.The Agency will receive the margin % they apply to the
                MPZ pricing.
              </td>
              <td>Agency customers charged monthly in advance</td>
              <td>Immediately on the date of each invoice.</td>
            </tr>
          </table>

          <p>
            The Agency acknowledges that this Order Form is entered into subject
            to the terms of the Agreement entered into between the Agency and
            the Company from the Effective Date including its schedules and all
            documents referred to therein.
          </p>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "DataProcessingAgreement",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },
  data() {
    return {
      meta: {
        title: "Reseller Agreement - Transpond",
        description: "Take a look at the Transpond Reseller Agreement",
      },
    };
  },
};
</script>
