<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <navigation></navigation>

  <PageHeader title="Acceptable Use Policy"> </PageHeader>

  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-3 mb-20">
        <TermsNav></TermsNav>
      </div>
      <div class="col-xs-12 col-md-6 col-md-offset-1">
        <div class="longform">
          <h5>
            THESE RULES SET OUT WHAT YOU CAN AND CANNOT DO WHEN USING OUR
            WEBSITE AND SERVICES - PLEASE READ CAREFULLY BEFORE USE
          </h5>

          <p>
            This acceptable use policy (<strong>"AUP"</strong>) sets out the
            terms under which you (<strong>"you"</strong>) may access the
            services made available at Transpond.io, ("Services"). This AUP
            supplements all other documents, policies and terms governing your
            use of our Services. This AUP applies to all users of the Services.
          </p>
          <p>
            You are not allowed to assist or engage others in any way that would breach the terms of this AUP. 
            We will enforce and ensure compliance with this AUP, including dealing with complaints and blocking and restricting access to our Services.
          </p>
          <p>
            Your use of our Services means that you accept, and agree to abide
            by, the terms of this AUP.
          </p>
          <p>
            The Services are operated by MPZMail Limited ("we/us"). We are
            registered in England and Wales under company number 11234241 and
            our registered office is at Level 1, 20 Dale Street, Manchester, M1
            1EZ, England.
          </p>

          <h3>1. Notifying us of Breaches of this AUP</h3>
          <p>
            We encourage users to report suspected breaches of the terms of this AUP. 
            Where you receive any content that breaches the terms of this AUP or you know or suspect that there is a breach of the terms of this AUP,
            please notify us as set out at the end of this AUP attaching a copy of the content that you believe breaches the terms of this AUP.
            We shall investigate all notifications, respond in the way we consider appropriate in the circumstances and notify you of the outcome of our investigation.
          </p>


          <h3>2. General Rules and Guidelines</h3>

          <p>
            Your account may not generate abuse complaints that, in our sole
            discretion, exceed industry norms. If you generate an unreasonable
            amount of abuse reports your account will be paused and one of the
            Transpond team will be in contact.
          </p>
          <p>
            Transpond operates a fair use policy. For free, monthly or annual
            subscriptions you may send up to 12 times the subscriber limit of
            your chosen subscription per month. If you exceed this limit
            Transpond may limit sending on your account until the next billing
            date has been reached.
          </p>
          <p>
            The Transpond platform has been developed to enable you to
            communicate with email subscribers that have opted-in (i.e.
            expressly given their permission) to receive information from your
            company. You must follow any posted guidelines regarding content and
            commercial activity limitations. All email addresses that you use
            must be solely derived from permission based lists. Transpond may
            not be used to send emails to individuals that have not opted-in to
            receive information via email from your company.
          </p>
          <p>You may only use the Services for lawful purposes.</p>

          <p>You may not use the Services:</p>
          <ul>
            <li>
              in any way that breaches any applicable local, national or
              international law or regulation;
            </li>
            <li>
              in any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect;
            </li>
            <li>
              for the purpose of harming or attempting to harm others, or for
              the purposes of promoting the same;
            </li>
            <li>
              to send, knowingly receive, upload, download, use or re-use any
              material which does not, or encourages others to not, comply with
              our content standards set out in clause 2;
            </li>
            <li>
              to encourage other users to use violence or commit illegal or
              criminal acts;
            </li>
            <li>
              to transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (<strong>"spam"</strong>) or distribute
              malware or any items of a destructive or deceptive nature;
            </li>
            <li>
              to falsify any protocol or email header information (e.g.
              <strong>"spoofing"</strong>);
            </li>
            <li>
              to collect user content or otherwise search or scrape our website
              using automated means (such as harvesting bots, robots or
              scrapers) without our permission;
            </li>
            <li>
              to knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware or circumvent our security or
              authentication measures;
            </li>
            <li>
              to represent yourself as our agent, partner or affiliate, or make
              any representations, warranties or guarantees in respect of the
              Services;
            </li>
            <li>
              for the collection, storage or processing of any personal data,
              unless you have received prior consent from the individual whose
              personal data is being used. No special category personal data may
              be collected;
            </li>
            <li>
              for any high risk use where failure or fault of the Services could
              lead to death or serious bodily injury of any person, or to severe
              financial, physical or environmental damage;
            </li>
            <li>if you are under 18.</li>
          </ul>

          <p>You agree not to:</p>
          <ul>
            <li>
              use meta tags, hidden text or metadata with our trademark, logo,
              URL or product name without our written consent;
            </li>
            <li>
              upload large amounts of unwanted or repetitive user content, post
              unsolicited commercial messages in comments, captions or tags, or
              try to artificially boost views or comments;
            </li>
            <li>
              sell or transfer your account details to any other person for
              compensation;
            </li>
            <li>
              reproduce, duplicate, copy or re-sell any part of our Services in
              contravention of the provisions of any agreement you have with us;
            </li>
            <li>
              try to gain unauthorised access to or disrupt any part of the
              Services, data, account or network by any means;
            </li>
            <li>
              execute load, stress, performance, penetration or vulnerability
              tests;
            </li>
            <li>
              access without permission, or interfere with, damage or disrupt
              any part of the Services, equipment or network from which our
              Services operate, software used within the Services or equipment
              or network or software owned or used by any third party;
            </li>
            <li>
              solicit your account details or access any account that belongs to
              another user, or undertake any related actions that violate the
              privacy or publicity rights of another user;
            </li>
            <li>
              post anyone's identification documents, sensitive financial
              information or information that is reasonably deemed to be
              confidential or private within the Services.
            </li>
          </ul>

          <h3>3. Email Rules</h3>
          <h4>All email messages</h4>
          <ul>
            <li>
              Messages must originate from a valid ‘from' address (MPZMail
              product takes steps to verify this). Your company name, address,
              city, state, postcode and phone number must be clearly included.
            </li>
            <li>
              Unsubscribe must be clearly visible (MPZMail will automatically
              place an unsubscribe link in the email if one is not present).
            </li>
            <li>
              You must comply with unsubscribe requests within 7 business days
            </li>
            <li>
              An unsubscribed email address must not receive any further emails.
            </li>
            <li>
              You must maintain a record of all unsubscribe requests, including
              the day you removed the address from the subscription list. You
              must provide us with a copy of such records upon our request.
            </li>
          </ul>

          <h4>Adding New Members</h4>
          <p>
            You will use a "double opt-in" subscription method for all new list
            members. For the purpose of the Agreement, a "double opt-in" method
            shall mean that when you add a new member's email address to the
            list, that email address shall not be activated unless and until the
            new member receives a single confirmation e-mail from you requesting
            the member's consent to be added to the list, and Transpond receives
            from the new member a confirmation action (such as a confirmation
            email from the new member's email address) approving such action.
            The confirmation email sent by the Customer to new members may not
            include advertising or calls-to-action other than an appeal to
            confirm the member's subscription.
          </p>

          <h4>Importing Members</h4>
          <p>
            You may only import members previously obtained directly by you
            using the "double opt-in" procedures described above. You may not
            import opt-out members directly into your list under any
            circumstances. You MAY NOT import members from co-registered or
            purchased sources, regardless of the confirmation status of said
            members.
          </p>

          <h4>One time mailings</h4>
          <p>
            You may not use Transpond for one-time mailings to a list of members
            after which you substantially delete the membership and create a new
            list. Your membership must be a static, permanent list to which you
            add or delete new members and/or members subscribe or unsubscribe
            themselves in the ordinary course.
          </p>

          <h4>Importing Opted-in EMail Lists</h4>
          <p>
            The Transpond service gives its users all the tools necessary to
            build and maintain an opt-in email list. Transpond also allows you
            to import existing lists of email addresses. The email addresses
            that are imported must have opted in to receive messages from your
            company. These features make it easy for users to build and use
            lists of opt-in subscribers and eliminate the need to send
            unsolicited emails. Therefore, in the event that a complaint is
            received or an instance reported of an unsolicited email message
            sent by your company using Transpond, Transpond will investigate
            claims of unsolicited email messages sent using the service. If
            Transpond determines that a user is employing the service to send
            emails to recipients who have not opted in or recipients that have
            unsubscribed on a prior occasion, the account will be immediately
            terminated.
          </p>

          <h4>4. Content Standards</h4>
          <p>
            Some content (both subject and body content) cannot be sent through
            Transpond under any circumstances. This includes the following but
            is not limited to:
          </p>
          <ul>
            <li>gambling</li>
            <li>betting</li>
            <li>lead sales</li>
            <li>pornography/adult content</li>
            <li>pharmaceutical</li>
            <li>
              promotion or sale of products or services that are deemed unlawful
              within the United Kingdom
            </li>
            <li>
              work-at-home offers promoting "get rich quick", "build your
              wealth" and "financial independence" offerings
            </li>
          </ul>
          <p>
            If you are unsure about how this applies to your email content,
            please contact us before using the service.
          </p>

          <p>User content must not:</p>
          <ul>
            <li>defame any person;</li>
            <li>
              discriminate against any person based on race, sex, religion,
              nationality, disability, sexual orientation or age;
            </li>
            <li>infringe the intellectual property rights of any person;</li>
            <li>
              copy any person, or misrepresent the identity or affiliation of
              any person with any other person;
            </li>
            <li>deceive, or be likely to deceive, any person;</li>
            <li>
              breach any legal duty owed to a third party (such as a contractual
              duty or a duty of confidence);
            </li>
            <li>promote illegal activity;</li>
            <li>
              threaten, abuse or invade another's privacy, or cause annoyance,
              inconvenience or needless anxiety;
            </li>
            <li>
              give the impression that it emanates from or is approved or
              endorsed by, us if this is not the case; or
            </li>
            <li>
              advocate, promote or assist any unlawful act (such as copyright
              infringement or computer misuse).
            </li>
          </ul>
          <p>
            When uploading any user content and when placing tags on any images,
            you will be required to provide us with information about that
            content to enable other users to access it. You therefore must:
          </p>
          <ul>
            <li>provide true, accurate and complete information; and</li>
            <li>
              confirm that the relevant user content is not in breach of any
              laws both in the UK and the country in which the user content was
              uploaded.
            </li>
          </ul>
          <p>
            Failure to comply with the above obligations could result in your
            account being suspended, disabled or otherwise terminated and the
            relevant content removed, confiscated and disclosed along with your
            account details.
          </p>
          <p>
            Any user content that relates to comments must be accurate,
            genuinely held and compliant with applicable laws in the UK and in
            any country from which they are posted.
          </p>

          <h3>5. Outgoing Emails</h3>
          <p>
            When using the Services you must ensure that the following criteria
            is met for outgoing messages:
          </p>
          <ul>
            <li>The from address is a valid email address</li>
            <li>
              Your full contact information including mailing address and phone
              number is included in the message.
            </li>
            <li>An unsubscribe link is clearly visible in your message.</li>
            <li>You will review and abide by all Can-Spam requirements.</li>
            <li>
              You must also agree to follow standard Internet etiquette for
              email and any applicable laws which offer the following usage
              provisions:
            </li>
            <li>You must not harvest email addresses.</li>
            <li>
              You may only send out to those individuals that have given you
              explicit permission to email them.
            </li>
            <li>
              You must fully comply with unsubscribe requests. Full compliance
              means that you do not ever email the address unless the email
              address owner provides permission and rescinds the unsubscribe
              request.
            </li>
          </ul>

          <h3>6. Suspension and Termination</h3>

          <p>
            We will determine, in our discretion, whether there has been a
            breach of this AUP through your use of our Services. When a breach
            of this AUP has occurred, we may take such action as we deem
            appropriate. Where we remove, disable or moderate any material uploaded by you, 
            we will provide you with a statement of reasons setting out why this action has 
            been taken and inform you of our available redress mechanisms.
          </p>
          <p>
            Failure to comply with any provision of this AUP constitutes a
            material breach of contract and may result in us:
          </p>
          <ul>
            <li>
              immediately (whether temporarily or permanently) withdrawing your
              access to our Services;
            </li>
            <li>
              immediately (whether temporarily or permanently) removing any
              posting or material uploaded by you to our Services;
            </li>
            <li>issuing a warning to you;</li>
            <li>
              taking legal proceedings against you for the reimbursement of all
              costs on an indemnity basis (including, but not limited to,
              reasonable administrative and legal costs) resulting from the
              breach;
            </li>
            <li>taking further legal action against you; and</li>
            <li>
              disclosing such information to law enforcement authorities either
              as required or as we reasonably feel is necessary.
            </li>
          </ul>

          <p>
            We exclude all liability for our actions taken in response to
            breaches of this AUP. The responses described in this AUP are not
            limited, and we may take any other action we reasonably deem
            appropriate.
          </p>

          <h3>7. Use by Minors</h3>
          <p>
            The use of any of our Services by anyone under the age of 18 is
            prohibited. Any account holder registering on behalf of a minor or
            permitting the use of their account by a minor shall be in breach of
            this AUP.
          </p>

          <h3>8. Contact</h3>
          <p>
            You may contact us in relation to any queries you have in relation
            to this AUP or any documents referred to in it. Please direct all
            queries to:
            <a href="mailto:support@mpzmail.com">support@mpzmail.com</a>
          </p>
          <h3>9. Digital Service Act - Illegal Content</h3>
          <p>
            The Digital Services Act 2024 ("<strong>>DSA</strong>") is an EU Regulation that applies to all providers of digital services regardless of their location where:
            <ul>
              <li>they offer intermediary services in the European Union (“EU”); and</li>
              <li>recipients of the intermediary services have their place of establishment or are located in the EU.</li>
            </ul>
            
            <strong>Single Point of Contact</strong>
            Our EU Legal Representative is responsible for all direct communications with users located in the EU, the European Commission, EU Member States’ authorities, and the European Board for Digital Services regarding the application of the DSA.Datarep is our EU Legal Representative and is our single point of contact.
            <br/>
            <br/>
            Datarep is our EU Legal Representative for the purposes of the DSA. Entites and persons located in the EU can contact them as set out below in relation to DSA issues.
            
            <br/>
            <br/>
            Email: <a href="mailto:mpzmail@datarep.com">mpzmail@datarep.com</a>
            <br/>
            <br/>
            Online webform: <a href="https://www.datarep.com/data-request">www.datarep.com/data-request</a>
            <br/>
            <br/>
            <strong>Notices</strong>
            <br/>
            <br/>
            All notifications relating to the DSA should be sent to our EU Legal Representative.
            <br/>
            <br/>
            Please note that only issues within the scope of the DSA, should not be notified to our EU Legal Representative. All communications will be dealt with in English.
            <br/>
            <br/>
            <strong>Moderation of Content</strong>
            <br/>
            <br/>
            We have an internal team responsible for reviewing all content notices received, who will determine what actions need to be taken in respect of each request received. 
            <br/>
            <br/>
            We have automated systems in place which scan every email campaign for words, links or sender email addresses that are regularly seen in “spam” or “phishing” type emails, 
            or for content that violates our acceptable use policy. If there is a flag for one of these checks, 
            the campaign is quarantined and the account is blocked until the customer contacts our support team for further discussion.
            All email campaigns include a “report as junk” link allowing receivers to report emails which will automatically remove them from the senders active email list and 
            add them to the customers blacklist. 
            <br/>
            <br/>
            In addition, we complete manual checks of email templates, image files and “report as junk” submissions for anything violating the AUP and block accounts where necessary.
            <br/>
            <br/>
            Our Internal Complaint Handling process includes opening an investigation for all complaints received and as soon as possible determining the severity 
            of the complaint and acting accordingly. If we find the user has violated our terms of use, we will remove the content, block 
            the account and where applicable contact the account owner.
          </p>
          <p>Dated: 26 July 2024</p>
        </div>
      </div>
    </div>
  </div>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import SiteFooter from "@/components/SiteFooter";
import TermsNav from "@/components/Terms/TermsNav";
import PageHeader from "@/components/Header/PageHeader";

export default {
  name: "AcceptableUsePolicy",
  components: {
    MetaInfo,
    Navigation,
    SiteFooter,
    TermsNav,
    PageHeader,
  },
  data() {
    return {
      meta: {
        title: "Acceptable Use Policy - Transpond",
        description: "Take a look at the Transpond Acceptable Use Policy",
      },
    };
  },
};
</script>
