<template>
  <nav class="mega-menu mega-menu--features">
    <div class="container-fluid mega-menu__container">
      <div class="mega-menu__aside">
        <div class="mega-menu__aside-text">
          <img
            src="../../../assets/images/map.svg"
            alt="Map"
            title="Map"
            width="24"
          />
          <h6>Product Tour</h6>
          <p class="p--sm">
            Everything you need to create, send and monitor successful emails
            campaigns.
          </p>
        </div>
        <a class="mega-menu__link" href="/features">
          <button
            class="btn btn--tertiary btn--sm mega-menu__aside-btn"
            title="Product tour"
          >
            Take a look
          </button>
        </a>
      </div>
      <ul class="mega-menu__links">
        <li>
          <span class="mega-menu__link-category">Create</span>
          <ul class="mega-menu__link-column">
            <li class="mega-menu__item">
              <a class="mega-menu__link" href="/features/campaignbuilder">
                <h6 class="text-link">Campaign Builder</h6>
                <p class="p--sm pr-6">
                  Create emails that get results with the intuitive drag and
                  drop editor.
                </p>
              </a>
            </li>
            <li class="mega-menu__item">
              <a class="mega-menu__link" href="/features/signupforms">
                <h6 class="text-link">Signup Forms</h6>
                <p class="p--sm pr-6">
                  Capture data wherever you need to and nurture relationships.
                </p>
              </a>
            </li>
            <li class="mega-menu__item">
              <a class="mega-menu__link" href="/features/ai-content-assistant">
                <div class="mega-menu__item-title">
                  <h6 class="text-link">AI Content Assistant</h6>
                  <div class="mega-menu__new">New</div>
                </div>
                <p class="p--sm pr-6">
                  Start sending email campaigns quickly by letting AI generate content for you.
                </p>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <span class="mega-menu__link-category">Automate</span>
          <ul class="mega-menu__link-column">
            <li class="mega-menu__item">
              <a class="mega-menu__link" href="/features/automations">
                <h6 class="text-link">Marketing Automations</h6>
                <p class="p--sm pr-6">
                  Achieve more with a range of easy to use automations.
                </p>
              </a>
            </li>
            <li class="mega-menu__item">
              <a class="mega-menu__link" href="/features/transactionalemails">
                <h6 class="text-link">Transactional Email</h6>
                <p class="p--sm pr-6">
                  Send perfectly timed messages to keep your contacts engaged.
                </p>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <span class="mega-menu__link-category">Analyze</span>
          <ul class="mega-menu__link-column">
            <li class="mega-menu__item">
              <a class="mega-menu__link" href="/features/analytics">
                <h6 class="text-link">Advanced Analytics</h6>
                <p class="p--sm pr-6">
                  Understand how contacts engage with communications and keep
                  improving.
                </p>
              </a>
            </li>
            <li class="mega-menu__item">
              <a class="mega-menu__link" href="/features/websitetracking">
                <h6 class="text-link">Website Tracking</h6>
                <p class="p--sm pr-6">
                  Understand where visitors go and what they do on your website.
                </p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "FeaturesMenu",
};
</script>
