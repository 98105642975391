<template>
  <section class="homepage-hero pt-10 pb-10 sm:pt-20 sm:pb-20">
    <div class="container-fluid">
      <div class="row center-xs">
        <div class="row center-xs first-xs">
          <div class="col-xs-12 mb-20">
            <Image
              :file="homeHeroImage"
              class="homepage-hero__image"
              alt="Casual image of two people talking on a sofa"
              width="1240"
              height="528"
              loading="eager"
            ></Image>
          </div>
          <div class="col-xs-12">
            <CustomerLogos
              :isInverted="true"
              class=""
              heading="Trusted by customers worldwide"
            ></CustomerLogos>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from "../Image";
import CustomerLogos from "../CustomerLogos";

export default {
  name: "HomepageHero",
  components: {
    Image,
    CustomerLogos,
  },
  props: {},
  data() {
    return {
      homeHeroImage: "home/home-hero",
    };
  },
};
</script>
