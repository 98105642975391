<template>
  <section
    :class="[
      'content-section content-section--one-column',
      theme == 'dark'
        ? 'content-section--dark pt-20 sm:pt-40'
        : 'mt-20 sm:mt-40',
      overlay
        ? 'content-section--overlay pb-20 sm:pb-0'
        : theme == 'dark'
        ? 'pb-20 sm:pb-40'
        : 'mb-20 sm:mb-40',
    ]"
  >
    <div class="container-fluid">
      <div class="row between-sm">
        <div class="first-sm col-xs-12 col-sm-4 mb-10 sm:mb-20">
          <h4>
            {{ title }}
          </h4>
        </div>
        <div class="col-xs-12 col-sm-7 mb-10">
          <p v-if="content" class="p--lg">{{ content }}</p>
        </div>
      </div>
      <div class="row mb-10">
        <div class="col-xs-12">
          <div class="integrations-promo__logo-grid">
            <CapsuleLogo></CapsuleLogo>
            <StripeLogo></StripeLogo>
            <HubspotLogo></HubspotLogo>
            <SlackLogo></SlackLogo>
            <ZapierLogo></ZapierLogo>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs">
          <router-link
            v-if="buttonLink"
            :to="{ name: buttonLink }"
            title="{{buttonText}}"
            class="btn btn--secondary"
            >{{ buttonText }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CapsuleLogo from "../Svgs/CapsuleLogo";
import StripeLogo from "../Svgs/StripeLogo";
import HubspotLogo from "../Svgs/HubspotLogo";
import SlackLogo from "../Svgs/SlackLogo";
import ZapierLogo from "../Svgs/ZapierLogo";

export default {
  name: "IntegrationsPromo",
  components: {
    CapsuleLogo,
    StripeLogo,
    SlackLogo,
    HubspotLogo,
    ZapierLogo,
  },
  props: {
    theme: {
      type: String,
    },
    title: {
      type: String,
      default: "Integrate with the apps you need",
    },
    content: {
      type: String,
      default:
        "Connect Transpond with the apps you use to drive your business every day, including Capsule, Pipedrive, Hubspot and Stripe. Alternatively, use our API to push and pull data between applications however you want.",
    },
    buttonText: {
      type: String,
      default: "View all integrations",
    },
    buttonLink: {
      type: String,
      default: "Integrations",
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
