<template>
  <section :class="'announcement-bar announcement-bar--' + theme">
    <div class="container-fluid">
      <div class="row center-xs">
        <img
          class="mr-3"
          :src="require('../assets/images/icons/' + icon + '.svg')"
          :alt="alt"
        />
        <p class="p--sm">
          {{ text }}
          <a class="text-link text-link--bare" :href="link.url"
            >{{ link.text }} -></a
          >
        </p>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "AnnouncementBar",
  props: {
    text: {
      type: String,
    },
    link: {
      type: Array,
    },
    icon: {
      type: String,
    },
    alt: {
      type: String,
    },
    theme: {
      type: String,
      default: "light",
    },
  },
};
</script>