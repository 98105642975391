<script setup>
import textHelpers from "../functions/text";
</script>

<template>
  <MetaInfo :title="meta.title" :description="meta.description"></MetaInfo>

  <navigation
    announcementText="Let AI bring your email content to life."
    :announcementLink="{
      url: '/features/ai-content-assistant',
      text: 'Try our new AI Content Assistant',
    }"
    announcementIcon="ai-outline-dark"
  ></navigation>

  <PageHeader
    title="The <em>easy to use</em><br/> marketing platform"
    subtitle-text="Communicate with ease and grow your business"
  >
    <PageHeaderRating
      button-text="Get started"
      rating-text="Rated 4.2 on Capterra"
    >
    </PageHeaderRating>
  </PageHeader>

  <HomeHero></HomeHero>

  <SectionTwoColumnRight
    title="Grow Your Prospects"
    content="Easily design forms to capture important data from your website or other digital assets while ensuring you remain compliant with data legislation. You can then effortlessly sync data with other applications via Transpond."
    button-text="Learn more"
    button-link="SignupForms"
    image="home/signup-forms"
  ></SectionTwoColumnRight>

  <SectionTwoColumnLeft
    title="Create Standout Communications"
    content="Create beautiful email templates that respond perfectly on any device. The intuitive Transpond drag and drop editor makes it a breeze to create professional looking emails either from scratch, or using one of our templates as a starting point."
    button-text="Learn more"
    button-link="CampaignBuilder"
    image="home/responsive-emails-asset"
    theme="dark"
  ></SectionTwoColumnLeft>

  <SectionOneColumnCenter
    title="See What's Happening"
    content="Monitor and make decisions in real time as recipients engage with your communications. Transpond gives you greater insight so you can have precise control over your communications."
    button-text="Learn more"
    button-link="Analytics"
    image="home/ui"
    :overlay="true"
    height="640"
  ></SectionOneColumnCenter>

  <SectionTwoColumnRight
    title="Achieve More"
    content="Create automations with ease by simply dragging and dropping communications into a chain of events. Automatically send emails, wait for changes and define goals however you like."
    button-text="Learn more"
    button-link="Automations"
    image="home/automation"
    theme="dark"
  ></SectionTwoColumnRight>

  <IntegrationsPromo button-link="Integrations"></IntegrationsPromo>

  <footerCTA></footerCTA>

  <site-footer></site-footer>
</template>

<script>
import MetaInfo from "@/components/MetaInfo";
import Navigation from "@/components/navigation/Main";
import PageHeader from "@/components/Header/PageHeader";
import PageHeaderRating from "@/components/Header/PageHeaderRating";
import SectionTwoColumnLeft from "@/components/ContentSections/SectionTwoColumnLeft";
import SectionTwoColumnRight from "@/components/ContentSections/SectionTwoColumnRight";
import SectionOneColumnCenter from "@/components/ContentSections/SectionOneColumnCenter";
import IntegrationsPromo from "@/components/Homepage/IntegrationsPromo";
import HomeHero from "@/components/Homepage/Hero";
import FooterCTA from "@/components/FooterCTA";
import SiteFooter from "@/components/SiteFooter";

import axios from "axios";
import siteType from "../functions/siteType";

var isUkSite = siteType.lookup();
isUkSite = true;

export default {
  name: "HomePage",
  components: {
    MetaInfo,
    Navigation,
    PageHeader,
    PageHeaderRating,
    SectionTwoColumnLeft,
    SectionTwoColumnRight,
    SectionOneColumnCenter,
    IntegrationsPromo,
    FooterCTA,
    SiteFooter,
    HomeHero,
  },
  data() {
    return {
      activeTestimonial: 1,
      signupFormFirstName: "",
      signupFormEmail: "",
      signupFormError: "",
      themeData: [],
      isUkSite: isUkSite,
      appsData: [],
      meta: {
        title: "Marketing tools for business - Transpond",
        description:
          "Try Transpond - Easy to use  marketing suite. Create standout communications to send to your customers and grow your business.",
      },
    };
  },

  created() {
    axios
      .get("https://api.transpond.io/api/templates?limit=3")
      .then((response) => {
        this.themeData = response.data.Templates;
        return axios.get("https://api.transpond.io/api/applications?limit=10");
      })
      .then((response) => {
        this.appsData = response.data.Applications;
      });
  },
  methods: {
    clickTestimonial(theID) {
      this.activeTestimonial = Number(theID);
    },
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
    addSubscriber() {
      var headers = {
        headers: {
          "x-access-token":
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBY2NvdW50TWFzdGVySWQiOjYsIlNpdGVNYXN0ZXJJZCI6MSwiQXBpS2V5SWQiOjEwNSwiaWF0IjoxNTM0NDI3MTU2fQ.JMkZ02FQ__dLHyVvM0XNWCY3k28ge7FNCkCS11AbCq8",
        },
      };
      var postData = {
        emailAddress: this.signupFormEmail,
        blackListed: false,
        customFields: {
          61: this.signupFormFirstName,
        },
        active: true,
      };

      axios
        .post(
          "https://api.transpond.io/group/2446/subscriber",
          postData,
          headers
        )
        .then(() => {
          this.signupFormError = `<div class="text-primary">Awesome! Thanks for subscribing.. We'll send you an email soon! :D </div>`;
        })
        .catch((error) => {
          var errorText = error.response.data.error;
          if (errorText.toLowerCase() == "subscriber already bound to group")
            errorText = `<div class="text-primary">You're already on our list, there's no prizes for signing up twice</div>`;

          this.signupFormError = errorText
            ? '<div class="text-secondary">' + errorText + "</div>"
            : '<div class="text-secondary">Oh no! There was a problem, try again...</div>';
        });
    },
    clickApp(theApp) {
      this.$router.push({
        path:
          "/integrations/" +
          textHelpers.stringToSlug(theApp.appName + " email marketing") +
          "/" +
          theApp.id,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
