<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row between-sm pb-16 pt-16">
        <div class="col-xs-12 col-sm-3 mb-10 mb-sm-0">
          <LogoMark class="mb-4" theme="white"></LogoMark>
          <h5>
            The easy to use <br />
            marketing platform
          </h5>
        </div>
        <div class="col-xs-12 col-sm-8">
          <div class="footer__links">
            <div class="footer__links-column">
              <h6 class="footer__links-column-header">Transpond</h6>
              <ul class="footer__links-list">
                <li class="footer__links-list-item">
                  <router-link class="footer__links-link" to="/features"
                    >Features</router-link
                  >
                </li>
                <li class="footer__links-list-item">
                  <router-link class="footer__links-link" to="/pricing"
                    >Pricing</router-link
                  >
                </li>
                <li class="footer__links-list-item">
                  <router-link class="footer__links-link" to="/emailtemplates"
                    >Email Templates</router-link
                  >
                </li>
                <li class="footer__links-list-item">
                  <a
                    class="footer__links-link"
                    href="https://agency.mpzmail.com"
                    >Agencies &amp; Resellers</a
                  >
                </li>
              </ul>
            </div>
            <div class="footer__links-column">
              <h6 class="footer__links-column-header">Company</h6>
              <ul class="footer__links-list">
                <li class="footer__links-list-item">
                  <router-link class="footer__links-link" to="/about"
                    >About</router-link
                  >
                </li>
                <li class="footer__links-list-item">
                  <router-link class="footer__links-link" to="/media"
                    >Brand Resources</router-link
                  >
                </li>
                <li class="footer__links-list-item">
                  <router-link class="footer__links-link" to="/contactus"
                    >Contact Us</router-link
                  >
                </li>
                <li class="footer__links-list-item">
                  <router-link
                    class="footer__links-link"
                    to="/gdprcontactlocations"
                    >GDPR</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="footer__links-column">
              <h6 class="footer__links-column-header">Integrations</h6>
              <ul class="footer__links-list">
                <li class="footer__links-list-item">
                  <router-link
                    class="footer__links-link"
                    to="/integrations/capsule-email-marketing/8"
                    >Capsule</router-link
                  >
                </li>
                <li class="footer__links-list-item">
                  <router-link
                    class="footer__links-link"
                    to="/integrations/slack-email-marketing/53"
                    >Slack</router-link
                  >
                </li>
                <li class="footer__links-list-item">
                  <router-link
                    class="footer__links-link"
                    to="/integrations/zapier-email-marketing/41"
                    >Zapier</router-link
                  >
                </li>
                <li class="footer__links-list-item">
                  <router-link class="footer__links-link" to="/integrations"
                    >All Integrations</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="footer__links-column">
              <h6 class="footer__links-column-header">Resources</h6>
              <ul class="footer__links-list">
                <li class="footer__links-list-item">
                  <a class="footer__links-link" href="https://kb.transpond.io/"
                    >Support</a
                  >
                </li>
                <li class="footer__links-list-item">
                  <router-link class="footer__links-link" to="/blog"
                    >Blog</router-link
                  >
                </li>

                <li class="footer__links-list-item">
                  <a
                    class="footer__links-link"
                    v-on:click="TrackingHelpers.linkToApp('apidocs/1')"
                    target="_blank"
                    >Developer API</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row between-sm pb-7">
        <div class="col-xs-12 col-sm-4 mb-4 mb-sm-0">
          <div class="row">
            <a
              class="footer__social-link"
              href="https://twitter.com/Transpondio"
            >
              <TwitterIcon class="footer__social-icon"></TwitterIcon>
            </a>
            <a
              class="footer__social-link"
              href="https://www.facebook.com/transpond.io"
            >
              <FacebookIcon class="footer__social-icon"></FacebookIcon>
            </a>
            <a
              class="footer__social-link"
              href="https://www.instagram.com/transpond.io/"
            >
              <InstagramIcon class="footer__social-icon"></InstagramIcon>
            </a>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="row start-xs end-sm">
            <div class="footer__legal mb-4 mr-4 mb-sm-0">
              © {{ year }} Transpond. All Rights Reserved.
            </div>
            <div class="footer__legal-links">
              <router-link class="footer__legal-link" to="/terms"
                >Terms</router-link
              >
              <router-link class="footer__legal-link" to="/privacy"
                >Privacy</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <CookieMessage></CookieMessage>
</template>

<script>
import TwitterIcon from "./Svgs/TwitterIcon";
import FacebookIcon from "./Svgs/FacebookIcon";
import InstagramIcon from "./Svgs/InstagramIcon";
import LogoMark from "./Svgs/Logo/LogoMark";
import CookieMessage from "@/components/CookieMessage";
import TrackingHelpers from "@/functions/tracking";


export default {
  components: {
    TwitterIcon,
    FacebookIcon,
    InstagramIcon,
    LogoMark,
    CookieMessage,
  },
  setup()
  {
    return { TrackingHelpers }
  },
  data() {
    let year = new Date().getFullYear();

    return {
      showBanner: true,
      year: year
    };
  },
  ///component has mounted
  created() {
    //see if they have a cookie
    let hasCookie = this.getCookie("mpz-cookies-ok");
    if (hasCookie) {
      this.showBanner = false;
    }
  },

  //functions
  methods: {
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    turnBannerOff() {
      const d = new Date();
      d.setTime(d.getTime() + 36135 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = "mpz-cookies-ok=1; " + expires + ";path=/";
      this.showBanner = false;
    },
    // changeCurrency(newCurr) {
    //   this.currency = newCurr;
    //   this.getPricing();
    // },
  },
};
</script>
