<template>
  <section
    :class="'content-section content-section--dark pt-20 pb-20 mt-20 content-section--' + theme"
  >
    <div class="container-fluid">
      <div class="row between-xs">
        <div class="col-xs-12 mb-20">
          <Image
            v-if="image"
            :file="image"
            class="content-section__image"
            alt=""
          ></Image>

          <Video
            v-if="video"
            :file="video"
            class="content-section__image"
          ></Video>
        </div>
      </div>

      <div class="row between-xs">
        <div class="col-xs-12 col-sm-5">
          <h4 v-if="title">{{ title }}</h4>
        </div>

        <div class="col-xs-12 col-sm-6">
          <ul v-if="steps" class="steps">
            <li v-for="(step,index) in steps" v-bind:key="index">
              <h5>{{ step.title }}</h5>
              <p>{{ step.content }}</p>
            </li>
          </ul>

          <div v-if="accordion" class="accordion">
            <AccordionItem
              v-for="item in accordion" v-bind:key="item.name"
              class="accordion__item--white"
              :active="item.name === selected"
              @setSelected="() => setSelected(item)"
            >
              {{ item.name }}
              <template v-slot:content>
                {{ item.content }}
              </template>
            </AccordionItem>
          </div>
          
          <div v-if="paragraphs" class="paragraphs">
            <p v-for="paragraph in paragraphs" :key="paragraph.id">
              {{ paragraph }}
            </p>
          </div>
          
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import Image from "../Image";
import Video from "../Video";
import AccordionItem from "../Accordion/AccordionItem";

export default {
  name: "SectionHero",
  components: { Image, AccordionItem, Video },
  props: {
    theme: {
      type: String,
    },
    image: {
      type: String,
    },
    video: {
      type: String,
    },
    title: {
      type: String,
    },
    steps: {
      type: Array,
    },
    accordion: {
      type: Array,
    },
    paragraphs: {
      type: Array,
    },
    selectedItem: {
      type: String,
    }
  },
  data() {
    return {
      selected: this.selectedItem
    }
  },
  methods: {
    setSelected(item) {
      this.selected = item.name;
    },
  },
};
</script>