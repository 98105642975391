<template>
  <FeaturePageLayout
    :pageTitle="meta.title"
    :pageDescription="meta.description"
  >
    <PageHeaderFeature
      title="Create forms to keep your contacts growing"
      subtitleText="Keep attracting new contacts to market to by ensuring they always have a way to pass their details to you."
      buttonText="Get started"
      buttonLink="Pricing"
    ></PageHeaderFeature>

    <SectionHero
      theme="green"
      image="signup-forms-hero"
      title="Turn visitors into subscribers"
      :accordion="[
        {
          name: 'Landing page redirects',
          content:
            'To provide confirmation to the user that they have successfully subscribed via a signup form, you can create a custom designed landing page that is hosted by Transpond.',
        },
        {
          name: 'Confirmation email',
          content:
            'Transpond supports “double opt-in”, a process that sends a confirmation email that acts as an extra layer of confirmation to verify each email address.',
        },
        {
          name: 'Duplicate email detection',
          content:
            'When an email address is submitted via a signup form, Transpond automatically scans to detect any duplicates. If the email address already exists, you can opt to redirect the user to another URL, show a message on the signup form or direct them to update their profile.',
        },
      ]"
      selectedItem="Landing page redirects"
    ></SectionHero>

    <SectionImageSwitcher
      title="Hosted vs Embedded forms"
      content="Use Transpond’s simple form builder to create a landing page that is perfectly on brand and brings in the exact data you want to capture from your audience. Or add an embedded form right onto your website for a seamless signup experience."
      :items="[
        { name: 'Embedded Form', image: 'embedded-form' },
        { name: 'Hosted Form', image: 'hosted-form' },
      ]"
      activeImage="Embedded Form"
    ></SectionImageSwitcher>

    <SectionTwoColumnLeft
      title="Track signups with analytics"
      content="Monitor how many visitors are seeing and engaging with your signup forms. Within Transpond you can view the number of impressions a signup form is receiving, as well as the overall conversion rate. Optimizing the design, content and location of your signup forms can all help to improve how well your forms are performing."
      image="track-signups"
    ></SectionTwoColumnLeft>

    <SectionStepsTwoColumnRight
      title="It's easy to get started with signup forms"
      image="signup-forms-get-started"
      :steps="[
        {
          title: 'Pick your pot',
          content: 'Select or create the group you want to add contact data to',
        },
        {
          title: 'Choose form type',
          content:
            'Decide on the type of form you’d like to create along with the theme, then preview',
        },
        {
          title: 'Send it live',
          content: 'Add the form where you need to, and check the metrics',
        },
      ]"
    ></SectionStepsTwoColumnRight>
  </FeaturePageLayout>
</template>

<script>
import FeaturePageLayout from "@/layouts/FeaturePageLayout.vue";
import PageHeaderFeature from "@/components/Header/PageHeaderFeature.vue";
import SectionHero from "@/components/Features/SectionHero.vue";
import SectionTwoColumnLeft from "@/components/ContentSections/SectionTwoColumnLeft";
import SectionImageSwitcher from "@/components/ContentSections/SectionImageSwitcher.vue";
import SectionStepsTwoColumnRight from "@/components/ContentSections/SectionStepsTwoColumnRight.vue";

export default {
  name: "CampaignBuilder",
  components: {
    FeaturePageLayout,
    PageHeaderFeature,
    SectionHero,
    SectionTwoColumnLeft,
    SectionStepsTwoColumnRight,
    SectionImageSwitcher,
  },
  data() {
    return {
      meta: {
        title: "Signup forms - Transpond",
        description:
          "Create forms to keep your contacts growing. Keep attracting new contacts to market by ensuring they always have a way to pass their details to you.",
      },
    };
  },

  methods: {
    openWindow: function (link, self) {
      window.open(link, self ? "_self" : "_blank");
    },
  },
};
</script>
