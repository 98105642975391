<template>
  <template v-if="showBanner">
    <div class="cookie-message">
      <div class="cookie-message__content">
        <p>By continuing to use this site you consent to the use of cookies in accordance with our <router-link to="/cookiepolicy" title="Cookie Policy" class="text-link">cookie policy</router-link></p>
      </div>
      <div class="cookie-message__button">
        <div class="btn btn--primary"
        v-on:click="turnBannerOff"
        >
        Close
        </div>
      </div>
    </div>
  </template>
</template>

<script>

export default {
  name: "CookieMessage",
  data() {
    return {
      showBanner: true,
    };
  },
  ///component has mounted
  created() {
    //see if they have a cookie
    let hasCookie = this.getCookie("mpz-cookies-ok");
    if (hasCookie) {
      this.showBanner = false;
    }
  },

  //functions
  methods: {
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    turnBannerOff() {
      const d = new Date();
      d.setTime(d.getTime() + 36135 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = "mpz-cookies-ok=1; " + expires + ";path=/";
      this.showBanner = false;
    },
    // changeCurrency(newCurr) {
    //   this.currency = newCurr;
    //   this.getPricing();
    // },
  },
}

</script>