<template>
  <section>
    <div class="container-fluid">
      <div class="pricing-table__wrapper">
        <div class="selector-wrapper">
          <p class="mb-4">How many contacts do you have?</p>
          <SliderSelector
            @setSubscriberCount="setSubscriberCount"
            :count="subscriberCount"
            :step="1"
            :min="0"
            :max="markers.length - 1"
          ></SliderSelector>
        </div>
        <div class="toggles-container mb-2">
          <ToggleSwitch
            optionOne="USD"
            optionTwo="GBP"
            :value="currency"
            :onSwitch="toggleCurrency"
          ></ToggleSwitch>
          <ToggleSwitch
            optionOne="annually"
            optionTwo="monthly"
            :value="frequency"
            :onSwitch="toggleFrequency"
          ></ToggleSwitch>
        </div>
        <div class="pricing-tiers__wrapper">
          <PricingCard
            :currencySymbol="currencySymbol"
            :perFreq="perFreq"
            title="Free"
            :price="0"
            :isDisabled="isPlanDisabled('Free')"
            plan-description="All of the basics you need to get started."
            :limitations="['Up to 1 User Login']"
            :features="['Up to 1 reply tracking mailbox']"
          ></PricingCard>

          <PricingCard
            :currencySymbol="currencySymbol"
            :perFreq="perFreq"
            title="Essentials"
            plan-description="The most affordable plan offering everything you need to simply send campaigns."
            :price="getPlanPrice('Essentials')"
            :isDisabled="isPlanDisabled('Essentials')"
            :limitations="['Up to 3 User Logins']"
            :features="[
              'Up to 3 reply tracking mailboxes',
              'Unlimited Email Sends',
              '1 Automation',
              '1 Automation Condition Step',
              'RSS Feed Dynamic Sending',
              'Web Tracking for 1 site',
            ]"
          ></PricingCard>

          <PricingCard
            :currencySymbol="currencySymbol"
            :perFreq="perFreq"
            title="Professional"
            plan-description="Some of our more advanced features including automation and reply tracking."
            :price="getPlanPrice('Professional')"
            :isDisabled="isPlanDisabled('Professional')"
            :limitations="['Up to 5 User Logins']"
            :features="[
              'Up to 5 reply tracking mailboxes',
              'Unlimited Email Sends',
              'Unlimited Automations',
              '5 Automation Condition Steps',
              'Split Tests',
              'Custom Template Blocks',
              'RSS Feed Dynamic Sending',
              'Web Tracking for up to 5 sites',
            ]"
          ></PricingCard>

          <PricingCard
            :currencySymbol="currencySymbol"
            :perFreq="perFreq"
            title="Premium"
            plan-description="The highest level plan which includes everything you'll need."
            :price="getPlanPrice('Premium')"
            :isDisabled="isPlanDisabled('Premium')"
            :customPricing="isPlanDisabled('Premium')"
            :limitations="['Unlimited User Logins']"
            :features="[
              'Unlimited reply tracking mailboxes',
              'Unlimited Email Sends',
              'Unlimited Automations',
              'Unlimited Automation Condition Steps',
              'Split Tests',
              'Custom Template Blocks',
              'RSS Feed Dynamic Sending',
              'Web Tracking for unlimited sites',
            ]"
          ></PricingCard>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PricingCard from "./PricingCard";
import ToggleSwitch from "./ToggleSwitch";
import SliderSelector from "./SliderSelector";
import { getPricingData } from "../../dataRequest";
import TrackingHelpers from "@/functions/tracking";
export default {
  //used for ref above
  components: { PricingCard, ToggleSwitch, SliderSelector },
  data() {
    return {
      pricePlans: [],
      currency: "GBP",
      currencySymbol: "£",
      frequency: "annually",
      perFreq: "year",
      subscriberCount: 0,
      pricingLoaded: false,
      isDisabled: false,
      customPricing: false,
      markers: [],
    };
  },
  ///component has mounted
  created() {
    getPricingData().then((data) => {
      //contact limit, tiers available
      let flattenedPrices = [
        {contactLimit: 250,tiers: [{tier: 0, priceGbp: 0, priceUsd: 0}]}
      ] 
      data.forEach((tier)=>{
        tier.prices.forEach((price)=>{
          const existing = flattenedPrices.find((p)=>p.contactLimit === price.maxUniqueSends);
          if(!existing){
            flattenedPrices.push({contactLimit: price.maxUniqueSends, tiers: [{tier: tier.id, priceGbp: price.monthly.priceGbp, priceUsd: price.monthly.priceUsd}]});
          }
          else
          {
            existing.tiers.push({tier: tier.id, priceGbp: price.monthly.priceGbp, priceUsd: price.monthly.priceUsd});
          }
        })
      })
      //sort by contact limit
      flattenedPrices = flattenedPrices.sort((a,b)=>a.contactLimit - b.contactLimit);
      this.subscriberCount = flattenedPrices[0].contactLimit;
      this.markers = flattenedPrices.map((p)=>p.contactLimit);
      this.pricePlans = data;
    });
  },
  //functions
  methods: {
    toggleCurrency() {
      this.currency = this.currency === "GBP" ? "USD" : "GBP";
      this.currencySymbol = this.currencySymbol === "£" ? "$" : "£";
    },
    toggleFrequency() {
      this.frequency = this.frequency === "monthly" ? "annually" : "monthly";
      this.perFreq = this.perFreq === "month" ? "year" : "month";
    },
    setSubscriberCount(count) {
      this.subscriberCount = this.markers[count];
    },
    getPlanPrice(name) {
      const plan = this.pricePlans.find((p) => p.name === name);
      if (!plan) return -1;
      let planPrices = plan.prices
        .slice()
        .sort((a, b) => a.maxUniqueSends - b.maxUniqueSends);
      let price = planPrices.find(
        (p) => p.maxUniqueSends >= this.subscriberCount
      );
      if (!price) {
        price = planPrices[planPrices.length - 1];
      }
      const currencyKey = this.currency === "USD" ? "priceUsd" : "priceGbp";
      return price[this.frequency][currencyKey];
    },
    isPlanDisabled(name) {
      const aboveFreeSubscriberLimit = this.subscriberCount > 1000;
      if (aboveFreeSubscriberLimit) {
        const plan = this.pricePlans.find((p) => p.name === name);
        if (!plan) return -1;
        let price = plan.prices.find(
          (p) => p.maxUniqueSends >= this.subscriberCount
        );
        if (this.subscriberCount > 999999) {
          return (this.customPricing = true);
        }
        if (!price) {
          return (this.isDisabled = true);
        }
      }
    },
    goRegister() {
      TrackingHelpers.linkToApp('register?welcome=true');
    },
  },
};
</script>
