<template>
  <svg :class="getClass" width="160" height="48" viewBox="0 0 160 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M155.202 29.215C153.889 31.5441 151.262 32.8605 148.434 32.8605C144.444 32.8605 141.362 30.3796 140.807 26.4306H159.949C160 25.9237 160 25.1643 160 24.6074C160 17.1138 155 12.3545 148.282 12.3545C141.565 12.3545 136.211 17.1138 136.211 24.6074C136.211 32.101 141.565 37.012 148.282 37.012C152.434 37.012 156.369 35.2334 158.56 31.7634L155.202 29.215ZM148.282 16.5061C152.071 16.5061 154.899 18.8861 155.454 22.8351H140.807C141.362 18.8861 144.393 16.5061 148.282 16.5061Z" fill="black" fill-opacity="0.25"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M128.132 36.5061H132.729V0H128.132V36.5061Z" fill="black" fill-opacity="0.25"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M114.103 32.9622C120.009 32.9622 119.57 26.2305 119.57 26.2305V12.8638H124.167V26.2305C124.167 26.2305 124.176 26.3846 124.174 26.6547C124.159 28.6542 123.954 37.0439 114.254 37.0439H113.95C104.251 37.0439 104.046 28.6542 104.031 26.6547C104.029 26.3846 104.038 26.2305 104.038 26.2305V12.8638H108.635V26.2305C108.635 26.2305 108.196 32.9622 114.103 32.9622Z" fill="black" fill-opacity="0.25"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M91.4646 22.2517C87.5301 21.6519 85.9769 20.7749 86.133 19.1813C86.4024 16.4247 89.6982 16.1133 91.799 16.2714C95.835 16.5733 96.6853 20.1004 96.7281 20.496L100.69 20.1179C100.457 17.5933 98.4359 12.6998 92.4465 12.2454C89.1784 11.9991 86.3319 12.7307 84.4284 14.3092C82.9758 15.5143 81.7697 17.3383 81.6286 19.2409C81.1801 25.2736 87.1235 26.1546 90.8164 26.7075C95.5069 27.4106 96.6021 27.9762 96.492 29.8255C96.4397 30.7042 95.9864 31.5589 95.3017 32.1182C94.3024 32.9348 92.3133 33.1756 90.4162 32.973C85.9856 32.5011 85.5973 29.6706 85.5703 29.1368L81.6286 29.2075C81.6706 31.6074 83.2476 36.2856 89.9542 37.0475C93.5607 37.4566 96.5173 36.4032 98.027 35.3093C99.7799 34.0383 100.736 31.7528 100.85 29.7517C101.206 23.5434 95.2248 22.8244 91.4646 22.2517Z" fill="black" fill-opacity="0.25"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M67.5396 12.3542C64.2565 12.3542 60.8719 13.8731 59.3068 16.5574V12.8611H54.7105V48H59.3068V32.9619C60.8719 35.6962 64.2565 37.0126 67.5396 37.0126C74.0045 37.0126 79.3583 32.2533 79.3583 24.7597C79.3583 17.266 74.0045 12.3542 67.5396 12.3542ZM67.0848 32.7768C63.2968 32.7768 59.1626 30.0687 59.1626 25.0051C59.1626 19.9924 63.1114 16.6925 67.2528 16.6925C71.4956 16.6925 74.9127 19.8995 74.9127 24.6087C74.9127 29.3172 71.3276 32.7768 67.0848 32.7768Z" fill="black" fill-opacity="0.25"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.6508 12.178C41.5383 12.178 41.4289 12.1868 41.3172 12.1892C41.3172 12.1892 41.3116 12.1892 41.3085 12.19V12.1828C41.3085 12.1828 37.3842 12.1955 33.6042 13.7295L34.9656 17.3679C36.2977 16.811 38.4841 16.1374 41.3085 16.0849C41.4234 16.0826 41.5343 16.0762 41.6508 16.0762C46.6774 16.0762 46.7685 20.7219 46.7685 20.7298V21.9333L38.8415 21.873C38.3296 21.8706 35.1122 21.8634 32.8775 24.0226C32.341 24.5414 31.9147 25.1459 31.5905 25.8164C31.1262 26.7792 30.8797 27.8827 30.8797 29.0933C30.8797 30.6567 31.2696 32.07 32.0034 33.2751C32.3378 33.8256 32.738 34.3364 33.2175 34.7908C34.7485 36.243 36.942 37.0509 39.3075 37.0509C39.5009 37.0509 39.6958 37.0461 39.8915 37.035C42.6073 36.8825 45.1297 35.8084 46.7622 33.1646L46.7614 36.5067H50.8496V21.5481C50.8496 17.7 48.4414 12.178 41.6508 12.178ZM39.6633 33.3386C38.1957 33.4197 34.968 32.6531 34.968 29.0933C34.968 28.1591 35.846 25.7719 38.7274 25.7719C41.6088 25.7719 46.6195 25.7965 46.6195 25.7965C46.6195 30.1769 44.0789 33.0916 39.6633 33.3386Z" fill="black" fill-opacity="0.25"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6274 32.4556C10.3543 32.4556 4.6969 26.5318 4.6969 19.139C4.6969 11.7471 10.3543 5.77235 17.6274 5.77235C21.1071 5.77235 24.1684 7.18957 26.4792 9.37736L29.2552 5.63412C26.2494 2.87992 22.1983 1.21564 17.6274 1.21564C7.77877 1.21564 0 9.2153 0 19.139C0 29.0127 7.77877 37.0123 17.6274 37.0123C21.6365 37.0123 25.254 35.7286 28.1132 33.5583L26.5917 29.2518C24.162 31.1703 20.7465 32.4556 17.6274 32.4556Z" fill="black" fill-opacity="0.25"/>
  </svg>
</template>

<script>
export default {
  name: "CapsuleLogo",
  props: {
    class: {
      type: String
    }
  },
  computed: {
    getClass() {
      return this.class;
    }
  }
}
</script>