<template>
  <div class="pricing-card col-xs-12 col-md-6 col-lg-3">
    <label v-if="perFreq === 'year' && price > 0" class="pill-label p--xxs"
      >Saving 10%</label
    >
    <h2 class="h5 mb-1">{{ title }}</h2>
    <p class="plan-description p--sm mb-4">
      {{ planDescription }}
    </p>
    <p
      :class="[
        { planDisabled: isDisabled },
        'mt-6',
        'p--sm',
        'pricing-card__price',
      ]"
    >
      <span v-if="!isDisabled" class="pricing-card__currency">{{
        currencySymbol
      }}</span>
      <span v-if="customPricing" class="p--sm mb-8"
        >Custom pricing available</span
      >
      <span v-else-if="isDisabled" class="p--sm mb-2">
        You've selected more contacts than this plan allows</span
      >
      <span v-else-if="perFreq === 'year'" class="h3 pricing-card__amount">
        {{ (price / 12).toFixed(0) }}
      </span>
      <span v-else class="h3 pricing-card__amount">
        {{ price.toFixed(0) }}
      </span>
    </p>

    <p class="p--xs mt-2" v-if="price === 0">
      &nbsp;
    </p>
    <p class="p--xs mt-2" v-else-if="perFreq === 'year'">
      Per month, billed annually
    </p>
    <p class="p--xs mt-2" v-else>Per month, billed monthly</p>
    <form v-if="customPricing" action="/contactus">
      <button class="btn btn--secondary btn--sm pricing-card__btn">
        Contact us
      </button>
    </form>

      <button
        v-else-if="isDisabled"
        v-on:click="TrackingHelpers.linkToApp('register?welcome=true')"
        :disabled="isDisabled"
        class="btn btn--secondary btn--sm pricing-card__btn"
      >
        Plan Not Available
      </button>
  

    <button v-else class="btn btn--secondary btn--sm pricing-card__btn" v-on:click="TrackingHelpers.linkToApp('register?welcome=true')">
      Create free account
    </button>

    <ul class="pricing-card__limitations">
      <li
        class="pricing-card__limitation"
        v-for="(limitation, index) in limitations"
        :limitation="limitation"
        :key="limitation.id"
        :index="index"
      >
        <p class="p--sm">
          <strong>{{ limitation }}</strong>
        </p>
      </li>
    </ul>
    <hr class="mt-6 mb-6 content-breaker" />
    <p v-if="title == 'Free'" class="mb-4 p--sm pricing-card__subheading">
      <strong>Free plan includes:</strong>
    </p>

    <p v-else class="mb-4 p--sm pricing-card__subheading">
      <strong>All paid plans include:</strong>
    </p>
    <ul>
      <li
        v-for="(feature, index) in features"
        :feature="feature"
        :key="feature.id"
        :index="index"
      >
        <p class="mb-1 p--sm">{{ feature }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import TrackingHelpers from "@/functions/tracking";

export default {
  name: "PricingCard",
  props: [
    "title",
    "plan-description",
    "features",
    "limitations",
    "price",
    "isDisabled",
    "customPricing",
    "currencySymbol",
    "perFreq",
  ],
  setup()
  {
    return { TrackingHelpers }
  },
  data() {
    return {
      planFeatures: this.features,
      planLimitations: this.limitations,
    };
  },
};
</script>
